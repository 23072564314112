import React from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { Container, Props as ContainerProps } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  "aria-label": string;
  size?: ContainerProps["size"];
}

const overlayStyles: React.CSSProperties = {
  backgroundColor: "#ffffffcc",
  zIndex: Number.MAX_SAFE_INTEGER,
};

const dialogContentStyles: React.CSSProperties = {
  backgroundColor: "transparent",
  zIndex: Number.MAX_SAFE_INTEGER,
  padding: 0,
};

export const Modal: React.FC<Props> = props => {
  return (
    <DialogOverlay
      style={overlayStyles}
      isOpen={props.isOpen}
      onDismiss={props.onClose}
    >
      <DialogContent
        aria-label={props["aria-label"]}
        style={dialogContentStyles}
      >
        <Container size={props.size}>
          <button
            className="modal-close-button"
            onClick={props.onClose}
            aria-label="Fechar modal"
          >
            <FontAwesomeIcon icon="times" />
          </button>
          {props.children}
        </Container>
      </DialogContent>
    </DialogOverlay>
  );
};
