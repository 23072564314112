import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBook,
  faCalendarCheck,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faChartBar,
  faCheck,
  faEye,
  faEyeSlash,
  faGraduationCap,
  faLock,
  faPlayCircle,
  faStar,
  faStopwatch,
  faUserPlus,
  faChevronRight,
  faPenSquare,
  faComments,
  faFileAlt,
  faFlag,
  faShoppingCart,
  faTimes,
  faSearch,
  faTimesCircle,
  faCheckCircle,
  faArrowLeft,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBook,
  faCalendarCheck,
  faGraduationCap,
  faChartBar,
  faCaretDown,
  faPlayCircle,
  faStopwatch,
  faCheck,
  faUserPlus,
  faLock,
  faCaretRight,
  faCaretLeft,
  faStar,
  faEye,
  faEyeSlash,
  faChevronRight,
  faPenSquare,
  faComments,
  faFileAlt,
  faFlag,
  faShoppingCart,
  faTimes,
  faSearch,
  faTimesCircle,
  faCheckCircle,
  faArrowLeft,
  faCaretUp,
);

export default library;
