import styled from "styled-components";
import { Colors } from "../../Assets/Colors";
import { Constants } from "../../Assets/Constants";

export const Toast = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.green};
  border-bottom-left-radius: ${Constants.radius.sm};
  border-bottom-right-radius: ${Constants.radius.sm};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 2rem;
  white-space: nowrap;
  color: #fff;

  p {
    margin-right: 15px;
  }

  .icon {
    font-size: 25px;
  }
`;
