import React from "react";
import { useAuthSelector } from "../../Store/reducers/auth";
import SignIn from "../../Views/SignIn";

/**
 * Tudo que estiver dentro deste componente só pode ser acessado por usuários autênticados, caso não haja usuário, a página de login será mostrada.
 */
export const AuthOnlyRoutes: React.FC = props => {
  const authState = useAuthSelector();

  if (authState.isLoading && !authState.user)
    return <div>Carregando usuário</div>;

  if (!authState.user) return <SignIn />;

  return <>{props.children}</>;
};
