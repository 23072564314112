import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthOnlyRoutes } from "./Components/AuthOnlyRoutes";
import Routes from "./Config/routes";
import TrialToast from "./Components/TrialToast";
import { useDispatch } from "react-redux";
import { AuthActions, useAuthSelector } from "./Store/reducers/auth";
import Layout from "./Components/Layout";
import { CourseActions } from "./Store/reducers/course";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SubscribedModal } from "./Components/SubscribedModal";
import { TrialEndedModal } from "./Components/TrialEndedModal";
import PurchaseCompletedToast from "./Components/PurchaseCompletedToast";
import { auth, db } from "./Config/firebase";
import { VideoTutorialModal } from "./Components/VideoTutorialModal";
import { FriendsProvider } from "./Context/Friends";
import { TrialPeriodProvider } from "./Context/TrialPeriod";
import { GamificationProvider } from "./Context/Gamefication";

const loadComponent = (path: string) =>
  React.lazy(() => import(`./Views/${path}`));

export const Router: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useAuthSelector();

  useEffect(() => {
    dispatch(AuthActions.setLoadingUser(true));

    const requestUserUpdateOnSnapshot = (userID: string) => {
      const removeListener = db
        .collection("users")
        .doc(userID)
        .onSnapshot(userSnapshot => {
          dispatch(AuthActions.getUserData(userID));
          if (userSnapshot.data()?.cadastro?.expiracaoTeste) {
            removeListener();
          }
        });
    };

    auth.onAuthStateChanged(user => {
      if (user) {
        requestUserUpdateOnSnapshot(user.uid);
      } else {
        dispatch(AuthActions.setLoadingUser(false));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (user) dispatch(CourseActions.syncActiveCourse());
  }, [dispatch, user]);

  return (
    <Suspense fallback={<div>Carregando Sistema...</div>}>
      <BrowserRouter>
        <TrialPeriodProvider>
          <ToastContainer />
          <SubscribedModal />
          <TrialEndedModal />
          <TrialToast />
          <VideoTutorialModal />
          <PurchaseCompletedToast />
          <Switch>
            <Route
              path={`${Routes.CHECKOUT}/:id?`}
              component={loadComponent("Checkout")}
            />
            <Route path={Routes.COURSES} component={loadComponent("Courses")} />
            <Route
              exact
              path={Routes.SIGN_UP}
              component={loadComponent("SignUp")}
            />
            <AuthOnlyRoutes>
              <Route
                exact
                path={Routes.PLACEMENT_TEST}
                component={loadComponent("PlacementTest")}
              />
              <Route
                exact
                path={Routes.FIRST_ACCESS}
                component={loadComponent("FirstAccess")}
              />
              <Route
                exact
                path={Routes.QUESTIONS}
                component={loadComponent("ModuleQuestions")}
              />
              <Route
                exact
                path={Routes.QUESTIONS_QUICKIE}
                component={loadComponent("Questions/Modes/Quickie")}
              />
              <Route
                exact
                path={Routes.QUESTIONS_TEST}
                component={loadComponent("Questions/Modes/Test")}
              />
              <Route
                exact
                path={Routes.QUESTIONS_SUBJECT}
                component={loadComponent("Questions/Modes/Subject")}
              />
              <Route
                exact
                path={Routes.QUESTIONS_STAGE}
                component={loadComponent("Questions/Modes/Stage")}
              />
              {/* Questions End */}
              <Route
                exact
                path={Routes.CONTENT}
                component={loadComponent("StudyBlock")}
              />
              <Route
                exact
                path={Routes.BUY_COURSE}
                component={loadComponent("BuyCourse")}
              />
              <Route
                exact
                path={`${Routes.PURCHASE_RESULT}/:result`}
                component={loadComponent("PurchaseResult")}
              />
              <Layout>
                <Route
                  exact
                  path={Routes.HOME}
                  component={loadComponent("Home")}
                />
                <FriendsProvider>
                  <Route
                    exact
                    path={Routes.PROFILE}
                    component={loadComponent("ProfileView")}
                  />
                  <Route
                    exact
                    path={Routes.PERFORMANCE}
                    component={loadComponent("PerformanceView")}
                  />
                </FriendsProvider>
                <Route
                  exact
                  path={`${Routes.COURSE}/:courseID`}
                  component={loadComponent("Course")}
                />
                <Route
                  exact
                  path={Routes.MY_PLAN}
                  component={loadComponent("MyPlanView")}
                />
                <Route
                  path={Routes.ALL_COURSES}
                  component={loadComponent("AllCoursesView")}
                />
                <Route
                  exact
                  path={Routes.ACHIEVEMENTS}
                  component={loadComponent("AchievementsView")}
                />
                <GamificationProvider>
                  <Route
                    exact
                    path={Routes.STUDY}
                    component={loadComponent("StudyView")}
                  />
                  <Route
                    exact
                    path={Routes.GAMEFICATION}
                    component={loadComponent("Gamefication")}
                  />
                </GamificationProvider>
                <Route
                  exact
                  path={Routes.STORE}
                  component={loadComponent("Store")}
                />
                <Route
                  exact
                  path={Routes.COURSE_DETAILS}
                  component={loadComponent("CourseDetails")}
                />
              </Layout>
            </AuthOnlyRoutes>
          </Switch>
        </TrialPeriodProvider>
      </BrowserRouter>
    </Suspense>
  );
};
