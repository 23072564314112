export const getFirebaseErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case "auth/email-already-in-use":
      return "Já existe uma conta com esse email.";
    case "auth/user-not-found":
      return "Usuário não encontrado.";
    case "auth/wrong-password":
      return "Senha incorreta.";
    case "auth/weak-password":
      return "A senha é muito fraca, escreva uma senha com pelo menos 8 caracteres.";
    case "auth/too-many-requests":
      return "Servidor temporariamente indisponivel.";
    case "auth/account-exists-with-different-credential":
      return "Já existe uma conta cadastrada com este email, mas não foi vinculada a esta opção de login. Por favor, tente fazer login com a opção que você usou para cadastrar a conta. Ex.: Email e senha.";
    default:
      return errorCode;
  }
};
