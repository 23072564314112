import styled from "styled-components";

export const ModalContentContainer = styled.div`
  text-align: center;

  p {
    font-size: 2em;
    margin-bottom: 1em;
  }
`;
