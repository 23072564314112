import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router-dom";

import { useUserCourses } from "../../Hooks/useUserCourses";
import { useDisclosure } from "../../Hooks/useDisclosure";

import { capitalizeAllFirst } from "../../Utils/string";

import { CourseActions, useCourseSelector } from "../../Store/reducers/course";
import { useAuthSelector } from "../../Store/reducers/auth";

import Routes from "../../Config/routes";

import {
  ClickHolder,
  Container,
  CourseSelector,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  Loading,
  SelectedCourse,
  Title,
  User,
  UserLetters,
} from "./styles";

const Header: React.FC = () => {
  const authState = useAuthSelector();
  const courseState = useCourseSelector();
  const { data: userCourses, refetch } = useUserCourses();

  const { isOpen, onClose, onToggle } = useDisclosure();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectableCourses = userCourses?.filter(
    course => course.uid !== courseState.activeCourse?.courseID,
  );

  const getUserInitials = () => {
    const nome = authState.user?.nome ?? "";
    const sobrenome = authState.user?.sobrenome ?? "";
    const firstChar = 0;
    return `${nome && nome[firstChar]}${sobrenome &&
      sobrenome[firstChar]}`.toUpperCase();
  };

  const updateActiveCourse = useCallback(
    async (courseUID: string) => {
      onClose();
      dispatch(CourseActions.updateActiveCourse(courseUID));
      refetch();
    },
    [dispatch, onClose, refetch],
  );

  const openAllCoursesPage = useCallback(
    () => history.push(Routes.ALL_COURSES),
    [history],
  );

  const renderDropdown = useCallback(() => {
    if (!isOpen) return <></>;

    return (
      <Dropdown>
        {selectableCourses?.map(item => (
          <DropdownItem
            key={item.courseID}
            onClick={() => updateActiveCourse(item.uid)}
          >
            {item.nome}
          </DropdownItem>
        ))}
        <DropdownDivider />
        <DropdownItem onClick={openAllCoursesPage}>Mais cursos</DropdownItem>
      </Dropdown>
    );
  }, [isOpen, openAllCoursesPage, selectableCourses?.map, updateActiveCourse]);

  const renderTitle = useCallback(() => {
    switch (pathname) {
      case Routes.HOME:
        return "Início";
      case Routes.ALL_COURSES:
        return "Escolha o seu próximo local de trabalho";
      case Routes.ACHIEVEMENTS:
        return "Quadro de medalhas";
      case Routes.GAMEFICATION:
        return "Gameficação";
      case Routes.COURSE_DETAILS:
        return "";
      default:
        const expungedPathname = pathname.replace("-", " ").replace("/", "");

        return capitalizeAllFirst(expungedPathname);
    }
  }, [pathname]);

  return (
    <>
      <Container>
        <Title>{renderTitle()}</Title>
        <User>
          {courseState.isLoading ? (
            <Loading />
          ) : (
            <UserLetters>{getUserInitials()}</UserLetters>
          )}
          <CourseSelector onClick={onToggle} disabled={courseState.isLoading}>
            <SelectedCourse>
              {courseState.activeCourse?.nome || "Nenhum curso ativo"}
            </SelectedCourse>
            <FontAwesomeIcon icon="caret-down" />
            {renderDropdown()}
          </CourseSelector>
        </User>
      </Container>
      {isOpen && <ClickHolder onClick={onToggle} />}
    </>
  );
};

export default Header;
