export const Colors = {
  black: "#4B4646",

  red: "#F74164",
  orange: "#FFBB33",

  pink: "#FF5E95",

  purple: "#5519D8",

  greenDark: "#00AC1D",
  green: "#67e374",

  blue: "#3EA5F9",
  blueTransparent: "#c0eaf5",
  blueAqua: "#9DE5F9",
  blueDark: "#0288D1",
  blueDarker: "#0262d1",
  blueCian: "#8FEAFA",
  blueLight: "#DFF1FF",
  blueLighter: "#3EA5F9",

  graySlate: "#727C8E",
  grayDarker: "#4B4646",
  grayDark: "#605A5A",
  gray: "#979797",
  grayHalfLight: "#ADB5BD",
  grayLight: "#E2E0E0",
  grayLighter: "#F5F5F5",

  yellow: "#FFBB33",

  white: "#fff",
  transparent: "transparent",
  background: "#FAFAFA",
};
