import { useQuery } from "react-query";
import { functions } from "../Config/firebase";

export interface State {
  codigo: number;
  uf: string;
  nome: string;
}

export interface City {
  codigo: number;
  nome: string;
  estado: number;
}

export const useStates = () => {
  const statesQuery = useQuery("states", fetchStates, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
    staleTime: 30000,
    retryDelay: () => 300000,
  });

  async function fetchStates(): Promise<State[]> {
    const { data: states } = await functions.httpsCallable("getStates")();

    if (!Array.isArray(states)) return [];

    return states.sort((a, b) => {
      if (a.nome > b.nome) return 1;
      else if (a.nome < b.nome) return -1;
      return 0;
    });
  }

  return statesQuery;
};

export const useCities = (stateID?: number) => {
  const statesQuery = useQuery(stateID?.toString(), fetchStates, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
    staleTime: 30000,
    retryDelay: () => 300000,
  });

  async function fetchStates(stateID?: string): Promise<City[]> {
    if (!stateID) return [];

    const { data: cities } = await functions.httpsCallable("getCityByState")({
      estado: stateID,
    });

    if (!Array.isArray(cities)) return [];

    return cities.sort((a, b) => {
      if (a.nome > b.nome) return 1;
      else if (a.nome < b.nome) return -1;
      return 0;
    });
  }

  return statesQuery;
};
