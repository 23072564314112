import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import { db } from "../../Config/firebase";
import { FriendsListInterface, FriendInterface } from "../../Interfaces/Friend";
import { useUserSelector } from "../../Store/reducers/auth";
import { useActiveCourse } from "../../Store/reducers/course";
import {
  addFriendByCodeOrPhone as addFriendByCodeOrPhoneService,
  handleFriendRequest,
} from "../../Services/Friend";
import clone from "clone";
import { Modal } from "../../Components/Modal";
import { useDisclosure } from "../../Hooks/useDisclosure";
import Button from "../../Components/Button";
import { ModalContentContainer } from "./style";

interface State {
  friends: FriendsListInterface["amigos"];
  isSendingRequest: boolean;
  isHandlingRequest: boolean;
  statusFriendsSearch: "loading" | "error" | "success";
  modalMessage: string;
}

interface UseFriendsState extends State {
  addFriendByToken: (params: AddFriendInterface) => Promise<void>;
  handlePendingRequest: (
    type: "accept" | "reject",
    key: number,
  ) => Promise<void>;
}

interface AddFriendInterface {
  userCode: string;
  courseName: string;
  token: string;
}

type Action =
  | { type: "SET_DATA"; friends: State["friends"] }
  | { type: "SET_SENDING_REQUEST"; value: State["isSendingRequest"] }
  | { type: "SET_HANDLING_REQUEST"; value: State["isHandlingRequest"] }
  | { type: "SET_STATUS_FRIEND_REQUEST"; status: State["statusFriendsSearch"] }
  | { type: "SET_MODAL_MESSAGE"; message: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, friends: action.friends };
    case "SET_SENDING_REQUEST":
      return { ...state, isSendingRequest: action.value };
    case "SET_HANDLING_REQUEST":
      return { ...state, isHandlingRequest: action.value };
    case "SET_STATUS_FRIEND_REQUEST":
      return { ...state, statusFriendsSearch: action.status };
    case "SET_MODAL_MESSAGE":
      return { ...state, modalMessage: action.message };
    default:
      return state;
  }
};

const stateContext = createContext<UseFriendsState>({} as UseFriendsState);

export const FriendsProvider: React.FC = ({ children }) => {
  const userState = useUserSelector();
  const courseState = useActiveCourse();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [state, dispatch] = useReducer(reducer, {
    friends: [],
    isSendingRequest: false,
    isHandlingRequest: false,
    statusFriendsSearch: "loading",
    modalMessage: "",
  });

  useEffect(() => {
    try {
      const getFriends = async () => {
        if (
          !userState?.uid ||
          !courseState?.courseID ||
          state.statusFriendsSearch !== "loading"
        )
          return;

        const friendsList = await db
          .collection("users")
          .doc(userState?.uid)
          .collection("cursos")
          .doc(courseState?.courseID)
          .collection("listaAmigos")
          .doc("amigos")
          .get();

        if (!friendsList.exists) {
          dispatch({
            type: "SET_STATUS_FRIEND_REQUEST",
            status: "error",
          });
          return;
        }

        const friendsListData = friendsList.data() as FriendsListInterface;

        dispatch({
          type: "SET_DATA",
          friends: friendsListData.amigos,
        });

        dispatch({
          type: "SET_STATUS_FRIEND_REQUEST",
          status: "success",
        });
      };

      getFriends();
    } catch (e) {
      dispatch({
        type: "SET_STATUS_FRIEND_REQUEST",
        status: "error",
      });
    }
  }, [
    courseState?.courseID,
    state.friends,
    state.statusFriendsSearch,
    userState?.uid,
  ]);

  const addFriendByToken = useCallback(
    async ({ token, userCode, courseName }: AddFriendInterface) => {
      try {
        if (token === userCode) {
          dispatch({
            type: "SET_MODAL_MESSAGE",
            message: "Você não pode adicionar a si mesmo!",
          });
          onOpen();

          return;
        }

        if (!courseState?.courseID) {
          dispatch({
            type: "SET_MODAL_MESSAGE",
            message: "Curso atual inválido!",
          });
          onOpen();

          return;
        }

        dispatch({
          type: "SET_SENDING_REQUEST",
          value: true,
        });

        await addFriendByCodeOrPhoneService({
          cursoID: courseState?.courseID,
          codeOrPhone: token,
        });

        dispatch({
          type: "SET_MODAL_MESSAGE",
          message: "Solicitação enviada com sucesso!",
        });
        onOpen();
      } catch (e) {
        let message;

        switch (e.message) {
          case "User Not Found.":
            message = "Usuário não encontrado.";
            break;
          case "User Not Registered in Course":
            message = `Usuário não registrado no curso ${courseName}`;
            break;
          default:
            message = "Um erro inesperado aconteceu!";
            break;
        }

        dispatch({
          type: "SET_MODAL_MESSAGE",
          message,
        });
        onOpen();
      } finally {
        dispatch({
          type: "SET_SENDING_REQUEST",
          value: false,
        });
      }
    },
    [courseState?.courseID, onOpen],
  );

  const handlePendingRequest = async (
    type: "accept" | "reject",
    key: number,
  ) => {
    try {
      if (!courseState?.courseID) {
        dispatch({
          type: "SET_MODAL_MESSAGE",
          message: "Curso atual inválido!",
        });
        onOpen();

        return;
      }

      dispatch({
        type: "SET_HANDLING_REQUEST",
        value: true,
      });

      const targetUserID = state.friends[key].idUser;

      await handleFriendRequest({
        cursoID: courseState?.courseID,
        targetUserID,
        action: type,
      });

      const newList = clone(state.friends) as Array<FriendInterface>;

      newList.splice(key, 1);

      dispatch({
        type: "SET_DATA",
        friends: newList,
      });

      const action = type === "accept" ? "aceita" : "recusada";

      dispatch({
        type: "SET_MODAL_MESSAGE",
        message: `Solicitação ${action} com sucesso!`,
      });
      onOpen();
    } catch (e) {
      dispatch({
        type: "SET_MODAL_MESSAGE",
        message: "Um erro inesperado aconteceu!",
      });
      onOpen();

      dispatch({
        type: "SET_SENDING_REQUEST",
        value: false,
      });
    }
  };

  return (
    <stateContext.Provider
      value={{
        ...state,
        addFriendByToken,
        handlePendingRequest,
      }}
    >
      <Modal aria-label="Aviso" isOpen={isOpen} onClose={onClose}>
        <ModalContentContainer>
          <p>{state.modalMessage}</p>
          <Button onClick={onClose}>Ok</Button>
        </ModalContentContainer>
      </Modal>
      {children}
    </stateContext.Provider>
  );
};

export const useFriends = (status?: FriendInterface["status"]) => {
  const state = useContext(stateContext);

  if (!state || !state.statusFriendsSearch)
    throw new Error("useFriends should be used inside FriendsProvider");

  if (status) {
    const friends = state.friends.filter(friend => friend.status === status);

    return {
      ...state,
      friends,
    };
  } else {
    return state;
  }
};
