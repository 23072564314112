import { db } from "../Config/firebase";

import { User } from "../Interfaces/User";

export const updateUserInfo = async (id: string, userData: Partial<User>) => {
  try {
    await db.doc(`users/${id}`).update(userData);
  } catch (e) {
    console.error(e);
  }
};
