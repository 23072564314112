import styled, { keyframes } from "styled-components";
import { Constants } from "../../Assets/Constants";
import { Colors } from "../../Assets/Colors";

const DROPDOWN_PADDING = "0.5rem";
const USER_LETTERS_DIMENSIONS = "3.3rem";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const Title = styled.h2`
  font-size: ${Constants.fontSize.lg};
  font-weight: bold;
  color: ${Colors.black};
  margin: 0;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const UserLetters = styled.div`
  height: ${USER_LETTERS_DIMENSIONS};
  width: ${USER_LETTERS_DIMENSIONS};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Constants.radius.full};
  border: solid 2px ${Colors.blueDark};
  color: ${Colors.blueDark};
  font-weight: bold;
  font-size: 1.5rem;
  margin-right: 1rem;
`;

export const SelectedCourse = styled.span`
  margin-right: 0.5rem;
`;

export const CourseSelector = styled.div<{ disabled: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
  color: ${Colors.black};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

export const ClickHolder = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 105%;
  right: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  padding: ${DROPDOWN_PADDING} 0;
  background: ${Colors.white};
  border-radius: ${Constants.radius.sm};
  box-shadow: 0px 10px 31px 7px rgba(0, 0, 0, 0.1);
`;

export const DropdownItem = styled.span`
  font-size: 1.3rem;
  padding: 0 ${DROPDOWN_PADDING};
  white-space: nowrap;
  &:hover {
    background: ${Colors.grayLight};
  }
`;

export const DropdownDivider = styled.hr`
  width: 100%;
  margin: 0.3rem 0;
  border-bottom-color: ${Colors.gray};
`;

export const Loading = styled.div`
  width: ${USER_LETTERS_DIMENSIONS};
  height: ${USER_LETTERS_DIMENSIONS};
  border-radius: ${Constants.radius.full};
  border: solid 2px ${Colors.blueDark};
  border-bottom-color: transparent;
  margin-right: 1rem;
  animation: ${rotate} 2s linear infinite;
`;
