import styled from "styled-components";
import Card from "../Card";
import { Colors } from "../../Assets/Colors";
import { Constants } from "../../Assets/Constants";
import { Images } from "../../Assets/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  changeProfilePicture?: boolean;
  isStatic?: boolean;
  noShadow?: boolean;
  filled?: boolean;
}

const setMotivationTextWidth = (line: number) => {
  if (line === 0) return "24rem";
  if (line === 1) return "30rem";
  if (line === 2) return "36rem";
  return "18rem";
};

export const Container = styled.div`
  position: relative;
`;

export const CardContainer = styled(Card)`
  color: ${Colors.blueDark};
  position: relative;
  overflow: hidden;
  display: flex;
  margin-bottom: 3rem;
  min-height: 14rem;
`;

export const TextContent = styled.div`
  width: 45rem;
  position: relative;
  z-index: 2;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: ${Constants.fontSize.xl};
  margin: 0 0 1rem;
  max-width: 45ch;
  line-height: 1.2;
`;

export const MotivationLine = styled.p<{ line: number }>`
  background: ${Colors.blueLight};
  margin-bottom: 0.5rem;
  margin-top: 0;
  padding: 0.2rem 0.5rem;
  width: ${({ line }) => setMotivationTextWidth(line)};
`;

export const GraphicalContent = styled.div<{ left?: boolean }>`
  position: absolute;
  top: 0;
  left: ${({ left }) => (left ? "0" : "initial")};
  right: ${({ left }) => (left ? "initial" : "0")};
  width: 55%;
  height: 100%;
`;

export const DiagonalRightBorder = styled.img.attrs({
  src: Images.diagonalRightBorder,
})`
  height: 14.2em;
  max-width: 14.2em !important;
  top: -0.2em;
  position: absolute;
  left: 0;
  z-index: 1;
  transform: scaleY(-1);
`;

export const BackgroundImage = styled.div<{
  image?: string;
  cutDirection?: "right" | "left";
}>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.grayLight};
  background-image: url(${({ image }) => image});
  background-size: contain;
  clip-path: ${({ cutDirection }) =>
    cutDirection === "left"
      ? "polygon(23% 0, 100% 0, 100% 100%, 0% 100%)"
      : "polygon(0 0, 77% 0, 100% 100%, 0% 100%)"};
`;

const userPictureDimension = "15rem";
export const UserProfilePicture = styled.div<{ left: boolean; image?: string }>`
  position: absolute;
  z-index: 10;
  left: ${({ left }) => (left ? "0" : "initial")};
  right: ${({ left }) => (left ? "initial" : "0")};
  top: 50%;
  transform: translateY(-50%);
  width: ${userPictureDimension};
  height: ${userPictureDimension};
  background-color: ${Colors.grayLight};
  background-image: ${({ image }) => (image ? `url(${image})` : undefined)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${Constants.radius.full};
`;

export const ProfileContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 2rem;
  top: 0;
  height: 100%;
  color: ${Colors.gray};
  z-index: 2;
`;

export const Button = styled.button<ButtonProps>`
  width: 10rem;
  height: 1.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${({ filled }) => (filled ? Colors.white : Colors.blueDark)};
  background: ${({ filled }) => (filled ? Colors.blueDark : Colors.white)};
  box-shadow: ${({ noShadow }) => (noShadow ? undefined : Constants.shadow)};
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};
  top: ${({ changeProfilePicture }) =>
    changeProfilePicture ? "105%" : "109%"};
  left: ${({ changeProfilePicture }) =>
    changeProfilePicture ? "50%" : undefined};
  right: ${({ changeProfilePicture }) =>
    changeProfilePicture ? undefined : "10%"};
  transform: ${({ changeProfilePicture, isStatic }) =>
    changeProfilePicture && !isStatic ? "translateX(-50%)" : undefined};
  border-radius: ${Constants.radius.sm};
  border: ${({ noShadow }) =>
    noShadow ? `solid 1px ${Colors.grayLight}` : undefined};

  & > * {
    cursor: pointer;
  }
`;

export const ButtonLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const InputFile = styled.input<InputProps>`
  position: absolute;
  pointer-events: none;
  opacity: 0;
`;

export const UserLabel = styled.span<{ locked?: boolean }>`
  margin-right: 0.5rem;
  color: ${({ locked }) => (locked ? Colors.graySlate : undefined)};
`;

export const UserData = styled.span<{ locked?: boolean }>`
  color: ${({ locked }) => (locked ? Colors.graySlate : Colors.blue)};
`;

export const LockIcon = styled(FontAwesomeIcon).attrs({ icon: "lock" })`
  position: absolute;
  top: 2px;
  left: -25px;
  margin-right: 0.5rem;
  color: ${Colors.gray};
`;

export const DiagonalLeftBorder = styled.img.attrs({
  src: Images.diagonalLeftBorder,
})`
  position: absolute;
  height: 100%;
  top: 0;
  right: -7%;
  z-index: 1;
`;

export const CropperOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  padding-bottom: 50px;
`;

export const CropperOverlayContent = styled.div`
  width: 30rem;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.white};
  padding: 8rem 0;
`;

export const CropperOverlayButtonsContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
  margin-top: 4rem;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
