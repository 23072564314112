import { db, firebase } from "../../../Config/firebase";
import { Context } from "./types";

export function recordStudyingTime({ currentModule }: Context) {
  if (!currentModule) return;

  const ONE_HOUR_IN_MILISECONDS = 60 * 60 * 1000;

  let initialTime = new Date().getTime();
  let timeoutId = startTimeout();

  function startTimeout() {
    return setTimeout(() => {
      initialTime = new Date().getTime();
    }, ONE_HOUR_IN_MILISECONDS);
  }

  function resetTimeout() {
    clearTimeout(timeoutId);
    timeoutId = startTimeout();
  }

  const events = ["mousemove", "click", "scroll", "keypress", "resize"];
  events.forEach(event => {
    document.addEventListener(event, resetTimeout, { passive: true });
  });

  return () => {
    try {
      const now = new Date().getTime();
      const timeSpentStudying = (now - initialTime) / ONE_HOUR_IN_MILISECONDS;
      const incrementHours = firebase.firestore.FieldValue.increment(
        timeSpentStudying,
      );

      db.doc(currentModule.path).update({ horasEstudadas: incrementHours });

      events.forEach(event => {
        document.removeEventListener(event, resetTimeout);
      });
    } catch (error) {}
  };
}

export const activities = {
  recordStudyingTime,
};
