import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Colors } from "../../Assets/Colors";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;

const Loader: React.FC<{ size?: number; hAlignment?: string }> = props => (
  <Container style={{ justifyContent: props.hAlignment }}>
    <ClipLoader size={props.size ?? 30} color={Colors.blue} />
  </Container>
);

export default Loader;
