import React, { useEffect } from "react";
import { useDisclosure } from "../../Hooks/useDisclosure";
import { Modal } from "../Modal";
import { useUserSelector } from "../../Store/reducers/auth";
import Button from "../Button";
import { Container, Video } from "./styles";
import Routes from "../../Config/routes";
import { useRouteMatch } from "react-router-dom";

export const VideoTutorialModal: React.FC<any> = React.memo(() => {
  const user = useUserSelector();
  const { isOpen, onClose, onOpen, isDirty } = useDisclosure(false);
  const match = useRouteMatch(Routes.ALL_COURSES);

  useEffect(() => {
    if (!user || !match) return;

    const hasCourse = user.idCursoAtivo;

    if (!isDirty && !hasCourse && match.isExact) {
      onOpen();
    }
  }, [isDirty, match, onOpen, user]);

  return (
    <Modal
      aria-label="tutorial em vídeo"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <Container>
        <Video
          src={
            "https://firebasestorage.googleapis.com/v0/b/aprovado-7f331.appspot.com/o/web-assets%2Ffirst-access-tutorial.mp4?alt=media&token=a8f2146b-23db-47e1-b49b-2a226c65e9b3"
          }
          controls
          autoPlay
        ></Video>
        <Button onClick={onClose}>Continuar</Button>
      </Container>
    </Modal>
  );
});
