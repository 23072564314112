import styled from "styled-components";
import { Colors } from "../../Assets/Colors";

export const Container = styled.div`
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  overflow-x: hidden;
`;

export const Left = styled.div`
  padding: 3em;
  flex: 0.5;

  > img {
    min-width: 15em;
    width: 15em;
    max-width: 15em;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2em;
  padding-bottom: 0;

  h1 {
    font-size: 2em;
    font-weight: bold;
    margin: 0;
    margin-right: 1rem;
    color: ${Colors.black};
  }

  a {
    margin-top: 0.6rem;
    color: ${Colors.blue};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 500px;
  padding: 2em;
  padding-top: 1.4em;
  margin-left: 7em;
`;

export const FormInputs = styled.div`
  width: 40vw;
  padding-top: 1.4rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;

  > button {
    width: 85%;
    margin-left: 15%;
  }

  > * {
    margin-bottom: 1rem;

    &:nth-child(2) {
      margin-top: 1em;
      margin-bottom: 5em;
    }

    &:last-child {
      margin: 0;
    }
  }
`;

export const ForgotPassword = styled.div`
  color: ${Colors.blue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Right = styled.div`
  flex: 1;
  user-select: none;

  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;

  min-width: 22.5vw;
  width: 22.5vw;
  max-width: 22.5vw;

  position: fixed;
  right: 0px;
  top: 0px;

  background-color: ${Colors.purple};

  > img {
    max-width: 490px;
    position: absolute;
    margin-left: -11vw;
    bottom: 0;
  }
`;

export const RightMessages = styled.div`
  font-size: 2em;
  font-weight: bold;

  color: ${Colors.purple};

  font-weight: bold;
  position: absolute;
  top: 7vh;

  min-width: 24vw;
  width: 24vw;
  max-width: 24vw;

  margin-left: -8.5vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    background-color: ${Colors.blueAqua};
    padding: 0.2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      min-width: 24vw;
      width: 24vw;
      max-width: 24vw;
      margin-bottom: 1rem;
    }

    &:last-child {
      min-width: 15vw;
      width: 15vw;
      max-width: 15vw;
    }
  }
`;
