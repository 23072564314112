import React from "react";
import { CardContainer } from "./styles";
import CardTitle from "../CardTitle";

declare interface CardProps {
  title?: string;
  titleAlignment?: string;
  className?: string;
  id?: string;
  onClick?: Function;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const Card: React.FC<CardProps> = ({
  id,
  className,
  title,
  titleAlignment = "center",
  children,
  onClick,
  disabled: pointer,
  style,
}) => {
  return (
    <CardContainer
      className={className}
      id={id || ""}
      onClick={(e: any) => onClick && onClick(e)}
      disabled={pointer}
      style={style}
    >
      {title ? (
        <CardTitle alignment={titleAlignment}>{title}</CardTitle>
      ) : (
        undefined
      )}
      {children}
    </CardContainer>
  );
};

export default Card;
