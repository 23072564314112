import React, { useCallback, useEffect } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useOffers } from "../../Hooks/useOffers";

import { maskBRL } from "../../Utils/masks";

import Routes from "../../Config/routes";

import { useActiveCourse } from "../../Store/reducers/course";

import { Images } from "../../Assets/Images";
import { Colors } from "../../Assets/Colors";

import {
  Container,
  CardHeader,
  CardBody,
  OfferContainer,
  OfferFetaures,
  Row,
  OfferPrice,
  BuyButton,
} from "./styles";
import "@reach/dialog/styles.css";
import Loader from "../Loader";
import { useTrialPeriod } from "../../Context/TrialPeriod";

interface BuildOfferParams {
  title: string;
  previousPrice: number;
  installments: number;
  currentPrice: number;
  benefits: Array<string>;
  onButtonClick?: () => void;
}

const overlayStyles: React.CSSProperties = {
  backgroundColor: "#ffffffcc",
  zIndex: Number.MAX_SAFE_INTEGER,
};

const dialogContentStyles: React.CSSProperties = {
  backgroundColor: "transparent",
  zIndex: Number.MAX_SAFE_INTEGER,
  padding: 0,
};

export const TrialEndedModal: React.FC = () => {
  const activeCourse = useActiveCourse();
  const history = useHistory();
  const { data: offers, status } = useOffers();
  const { pathname } = useLocation();
  const {
    isRequestPaymentModalOpen,
    showRequestPaymentModal,
  } = useTrialPeriod();

  const buildOfferOption = useCallback(
    ({
      title,
      previousPrice,
      installments = 0,
      currentPrice = 0,
      onButtonClick,
      benefits,
    }: BuildOfferParams) => {
      return (
        <OfferContainer>
          <h2>{title}</h2>
          <Row>
            <OfferFetaures>
              {benefits.map(benefit => (
                <li key={benefit}>
                  <FontAwesomeIcon
                    icon="check"
                    color={Colors.greenDark}
                    className="icon"
                  />
                  {benefit}
                </li>
              ))}
            </OfferFetaures>
            <OfferPrice>
              <p>
                {previousPrice && (
                  <>
                    de <span> {maskBRL(previousPrice)}</span>
                  </>
                )}
              </p>
              <p>
                por {installments}x
                <span>{maskBRL(currentPrice / installments)}</span>
              </p>
              <BuyButton onClick={onButtonClick}>Comprar</BuyButton>
            </OfferPrice>
          </Row>
        </OfferContainer>
      );
    },
    [],
  );

  useEffect(() => {
    const routesToHideToast = [Routes.BUY_COURSE, Routes.CHECKOUT];

    const shouldHideToastInThisRoute = !!routesToHideToast.find(route => {
      return pathname.startsWith(route);
    });

    if (shouldHideToastInThisRoute) {
      showRequestPaymentModal(false);
      return;
    }
  }, [pathname, showRequestPaymentModal]);

  return (
    <DialogOverlay
      style={overlayStyles}
      isOpen={isRequestPaymentModalOpen}
      onDismiss={() => {
        showRequestPaymentModal(false);
      }}
    >
      <DialogContent
        aria-label={"o período de teste acabou"}
        style={dialogContentStyles}
      >
        <Container>
          <CardHeader>
            <img src={Images.subscriptionSuccessCertification} alt="" />
            <h1>Seu período de teste acabou</h1>
            <p>Escolha um dos pacotes abaixo e mude de vida</p>
          </CardHeader>
          <CardBody>
            {status === "loading" && <Loader />}
            {status === "success" &&
              offers &&
              Array.isArray(offers) &&
              offers.filter(Boolean).map(offer => {
                return buildOfferOption({
                  title: offer.nome,
                  previousPrice: offer.precoDe,
                  currentPrice: offer.precoPor,
                  installments: offer.nMaxParcelas,
                  benefits: offer.benefits,
                  onButtonClick: () =>
                    history.push(Routes.BUY_COURSE, {
                      coursePath: activeCourse?.cursoRef,
                      courseId: activeCourse?.cursoRef.split("/").pop(),
                    }),
                });
              })}
          </CardBody>
        </Container>
      </DialogContent>
    </DialogOverlay>
  );
};
