import { Module } from "../../../Interfaces/Course";
import { db } from "../../../Config/firebase";
import { Context, LocalModule } from "./types";

export async function fetchModules({ block }: Context): Promise<LocalModule[]> {
  if (!block) return [];

  const blocksPath = `${block.volumePath}/blocos/${block.blocoID}/modulos`;
  const blocksRef = await db.collection(blocksPath).get();

  const modules = blocksRef.docs.map(doc => ({
    path: doc.ref.path,
    data: {
      id: doc.id,
      ...doc.data(),
    } as Module,
  }));

  return modules;
}

export const services = { fetchModules };
