/**
 *
 * Componente temporario enquando os dados dos modulos
 * não são melhor estruturados
 *
 */
import { Course, Block } from "../Interfaces/Course";

export const getModuleID = (block: Block) => {
  if (isRevision(block.blocoID)) return block.blocoID;

  return block.blocoID + "m" + ((block.nModulosCompletados || 0) + 1);
};

export const getModuleType = (blockID: string) => {
  if (isRevision(blockID)) return "revisao";

  return "modulo";
};

export const isModule = (blockID: string) => blockID.length !== 9;

export const isRevision = (blockID: string) => blockID.length === 9;

export const getModulePath = (
  courseState: Course | undefined,
  block?: Block,
) => {
  // Feito para que a função não precise ser utilizada assim:
  // getModulePath(courseState as Course)
  if (!courseState) return "";

  // True Path:
  //
  // users > {userId} >
  //   cursos > {cursoID} >
  //     volumes > {volumeID} >
  //       blocos > {blocoID} >
  //         modulos > {moduloID}

  return [
    courseState.volumeAtivoRef,
    "blocos",
    block?.blocoID || courseState.activeBlock?.blocoID,
    "modulos",
    getModuleID(block || courseState.activeBlock),
  ].join("/");
};

export const isInLastBlockOfVolume = (courseState: Course | undefined) => {
  // Feito para que a função não precise ser utilizada assim:
  // getModulePath(courseState as Course)
  if (!courseState) return false;

  const reverseBlocks = courseState.activeVolume.listaBlocos.reverse();

  for (const block of reverseBlocks) {
    if (block.status === "ativo") return true;

    if (["completado", "agendado", "bloqueado"].includes(block.status)) {
      return false;
    }
  }

  return false;
};

export const isInLastVolumeOfCourse = (courseState: Course) => {
  const lastVolumeName =
    courseState.listaVolumes[courseState.listaVolumes.length - 1].nome;

  if (courseState.activeVolume.nome === lastVolumeName) {
    return true;
  }

  return false;
};
