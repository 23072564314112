import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { authReducer } from "./reducers/auth";
import { courseReducer } from "./reducers/course";

const reducers = {
  auth: authReducer,
  course: courseReducer,
};

/**
 * Representa o estado global da aplicação, cada chave aponta para o retorno de um reducer
 */
export type AppState = {
  [T in keyof typeof reducers]: ReturnType<typeof reducers[T]>;
};

export const store = createStore(
  combineReducers<AppState>(reducers),
  composeWithDevTools(applyMiddleware(thunk)),
);
