import { useQuery } from "react-query";
import { db, auth } from "../Config/firebase";
import { Course } from "../Interfaces/Course";

export const useUserCourses = () => {
  const userCoursesQuery = useQuery("userCourses", fetchUserCourses);
  const user = auth.currentUser;

  async function fetchUserCourses() {
    const response = await db
      .collection("users")
      .doc(user?.uid)
      .collection("cursos")
      .get();

    const coursesList = response.docs.map(doc => ({
      uid: doc.id,
      ...doc.data(),
    }));

    return coursesList as Course[];
  }

  return userCoursesQuery;
};
