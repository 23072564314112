import { createGlobalStyle, css } from "styled-components";
import { Colors } from "./Assets/Colors";

const tabAfterStyles = css`
  content: "";
  top: 100%;
  left: 0;
  position: absolute;
  background: ${Colors.orange};
  width: 100%;
  height: 2px;
`;

export const GlobalStyles = createGlobalStyle`
  html {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
    color: ${Colors.gray};

    #root {
      height: 100vh;
    }

    .react-tabs {
      &__tab-list {
        border: none;
      }


      &__tab {
        border: none;
        color: ${Colors.grayDark};

        &:focus {
          box-shadow: initial;
          border: none;

          &::after {
            ${tabAfterStyles};
            opacity: 1;
          }
        }

        &::after {
          ${tabAfterStyles};
          opacity: 0;
        }

        &--selected {
          color: ${Colors.blue};

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
`;
