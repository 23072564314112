// Cases Convert

// camelCase
// PascalCase
// snake_case
// kebab-case

export const camelToSnake = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const camelToKebab = (str: string) =>
  str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

export const camelize = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/[^a-zA-Z 0-9]+/g, "");

// Transform

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeAllFirst = (str: string) => {
  return str
    .split(" ")
    .map(str => capitalizeFirst(str))
    .join(" ");
};

// Strip

export const revomeNonNumeric = (string: string) => string.replace(/\D/g, "");

export const clean = (str: string) => {
  return str.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
};

export const getUserNames = (name = "") => {
  const names = name.split(" ");

  const firstName = names[0];
  const lastName = names[names.length - 1];

  return { firstName, lastName };
};
