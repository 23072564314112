import styled from "styled-components";

import { Colors } from "../../Assets/Colors";
import GenericButton from "../../Style/GenericButton";
import { Props } from ".";

const getFontSize = (size?: Props["size"]) => {
  switch (size) {
    case "xs":
      return ".7rem";
    case "md":
      return "1rem";
    case "lg":
      return "1.3rem";
    default:
      return "1rem";
  }
};

const getColors = (
  variant?: Props["variant"],
  color?: Props["variantColor"],
) => {
  switch (variant) {
    case "outline":
      return `
        background-color: #fff;
        color: ${color ? Colors[color] : Colors.blue};
        border-color: ${color ? Colors[color] : Colors.blue};
      `;
    case "link":
      return `
        background-color: transparent;
        color: ${color ? Colors[color] : Colors.blue};
      `;
    case "ghost":
      return `
        background-color: transparent;
        color: ${color ? Colors[color] : Colors.blue};
      `;
    default:
      return `
        color: ${Colors.white};
        background-color: ${color ? Colors[color] : Colors.blue};
      `;
  }
};

const getHoverStylesForVariant = (
  variant?: Props["variant"],
  color?: Props["variantColor"],
) => {
  switch (variant) {
    case "outline":
      return `
      border-color:${color ? `${Colors[color]}90` : `${Colors.blue}90`};
     `;
    case "link":
      return `
      text-decoration: underline;
     `;
    case "ghost":
      return `
      background-color: ${color ? `${Colors[color]}30` : `${Colors.blue}30`};
     `;
    default:
      return "box-shadow: 0px 5px 10px rgba(0,0,0,.2);";
  }
};

const getDisabledStylesForVariant = (variant: Props["variant"]) => {
  switch (variant) {
    case "link":
      return `
        color: ${Colors.grayLight};
      `;
    case "outline":
      return `
        color: ${Colors.grayLight};
        border-color: ${Colors.grayLight};
      `;
    default:
      return `
        color: ${Colors.gray};
        background-color: ${Colors.grayLight};
        border-color: ${Colors.grayLight};
      `;
  }
};

export const Container = styled(GenericButton)<Props>`
  width: ${({ isBlock }) => (isBlock ? "100%" : "auto")};
  font-size: ${({ size }) => getFontSize(size)};
  border: 2px solid transparent;
  border-radius: ${({ isRounded }) => (isRounded ? ".6rem" : ".3rem")};
  padding: ${({ variant }) => (variant === "link" ? 0 : " 0.3rem 1.5rem;")};
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  ${({ variant, variantColor }) => getColors(variant, variantColor)};

  &:not(:disabled):hover {
    ${({ variant, variantColor }) =>
      getHoverStylesForVariant(variant, variantColor)};
  }

  &:disabled {
    pointer-events: none;
    ${({ variant }) => getDisabledStylesForVariant(variant)};
  }

  [data-icon-direction="left"] {
    margin-right: 10px;
  }
  [data-icon-direction="right"] {
    margin-left: 10px;
  }
`;
