import { assign } from "xstate";
import { Context, Event, LocalModule } from "./types";
import { analytics } from "../../../Config/firebase";

function checkIfIsLastModule(modulePath: string, modules: LocalModule[]) {
  const index = modules.findIndex(module => module.path === modulePath);

  return index + 1 === modules.length;
}

export const setBlock = assign<Context, Event>((ctx, evt) => {
  if (evt.type !== "START_STUDYING") return ctx;

  const block = evt.data;

  return {
    block: block,
  };
});

export const setModules = assign<Context, Event>((_ctx, evt) => ({
  modules: (evt as any).data,
  totalRemainingModules: (evt as any).data.length,
}));

export const resetContext = assign<Context, Event>(() => ({
  block: undefined,
  currentModule: undefined,
  modules: [],
  totalRemainingModules: 0,
  isLastModule: false,
}));

export const setInitialModule = assign<Context, Event>(({ block, modules }) => {
  const passableStatus = ["completado", "saltado"];
  if (passableStatus.includes(block?.status ?? "")) {
    const currentModule = modules[0];
    const isLastModule = checkIfIsLastModule(currentModule.path, modules);

    analytics.logEvent("select_modulo", {
      // eslint-disable-next-line @typescript-eslint/camelcase
      id_modulo: currentModule.data.id,
    });

    return {
      currentModule,
      isLastModule,
    };
  }

  const nextModuleIndex = block?.nModulosCompletados ?? 0;

  // Alguns blocos estavam vindo com o número de módulos completados maior que o número de módulos disponíveis, por isso a necessidade desta verificação
  const reachedLimit = nextModuleIndex > modules.length;
  const currentModuleIndex = reachedLimit ? 0 : nextModuleIndex;

  const totalRemainingModules = modules.length - currentModuleIndex;
  const currentModule = modules[currentModuleIndex];
  const isLastModule = checkIfIsLastModule(currentModule.path, modules);

  analytics.logEvent("select_modulo", {
    // eslint-disable-next-line @typescript-eslint/camelcase
    id_modulo: currentModule.data.id,
  });

  return {
    currentModule,
    isLastModule,
    totalRemainingModules,
  };
});

export const updateCurrentModule = assign<Context, Event>((_ctx, evt) => {
  return {
    currentModule: evt.type === "UPDATE_MODULE" ? evt.data : undefined,
  };
});

export const setNextModule = assign<Context, Event>(
  ({ modules, currentModule: previousModule, totalRemainingModules }) => {
    const nextModuleIndex =
      modules.findIndex(m => m.path === previousModule?.path) + 1;
    const nextModule = modules[nextModuleIndex];
    const currentRemainingModules = totalRemainingModules - 1;
    const isLastModule = checkIfIsLastModule(nextModule.path, modules);

    return {
      currentModule: nextModule,
      totalRemainingModules: currentRemainingModules,
      isLastModule,
    };
  },
);

export const setSubjectGoal = assign<Context, Event>(ctx => {
  const currentSubject = ctx.activeCourse?.materias.find(subject => {
    return ctx.block?.nomeMateria === subject.nome;
  });

  const subjectGoal = (currentSubject?.meta ?? 50) / 100;

  return {
    subjectGoal,
  };
});

export const setCourse = assign<Context, Event>((ctx, evt) => {
  if (evt.type !== "SET_COURSE") return ctx;

  return {
    activeCourse: evt.data,
  };
});

export const actions = {
  setBlock,
  setModules,
  resetContext,
  setInitialModule,
  updateCurrentModule,
  setNextModule,
  setSubjectGoal,
  setCourse,
};
