import styled from "styled-components";
import Card from "../Card";
import { Colors } from "../../Assets/Colors";
import { Constants } from "../../Assets/Constants";
import GenericButton from "../../Style/GenericButton";

export const Container = styled(Card)`
  overflow: hidden;
  padding: 0;
  max-width: 500px;
  margin: auto;
`;

export const CardHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(94, 184, 255);
  background: linear-gradient(
    180deg,
    rgba(94, 184, 255, 1) 80px,
    rgba(2, 136, 209, 1) 80px
  );
  padding-bottom: 30px;

  img {
    width: 90px;
    resize-mode: contain;
    transform: translate3d(0, 24%, 0);
    margin-bottom: 20px;
  }

  h1 {
    font-size: ${Constants.fontSize.sm};
    font-weight: 400;
    color: #fff;
    margin-bottom: 15px;
  }

  p {
    max-width: 22ch;
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: ${Constants.fontSize.base};
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 10px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const OfferContainer = styled.div`
  width: 95%;
  margin: auto;
  padding: 10px 10px 20px;

  h2 {
    font-size: ${Constants.fontSize.sm};
    font-weight: 600;
    color: ${Colors.blueDark};
    margin-bottom: 15px;
  }

  &:not(:last-of-type):after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid ${Colors.grayLight};
  }
`;

export const OfferFetaures = styled.ul`
  padding-bottom: 20px;
  margin: 0;

  list-style: none;

  li {
    color: ${Colors.gray};
  }

  .icon {
    width: 16px;
    margin-right: 5px;
  }
`;

export const OfferPrice = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p:first-child {
    color: ${Colors.blueDark};
    font-size: ${Constants.fontSize.base};

    span {
      font-size: ${Constants.fontSize.sm};
      font-weight: 600;
      color: ${Colors.orange};
      text-decoration: line-through;
      text-decoration-color: ${Colors.blueDark};
    }
  }

  p:nth-child(2) {
    font-size: ${Constants.fontSize.base};
    color: ${Colors.blueDark};
    margin-bottom: 20px;

    span {
      color: ${Colors.blueDark};
      font-size: ${Constants.fontSize.md};
      font-weight: 600;
      margin: 7px 0 4px;
    }

    span:last-of-type {
      color: ${Colors.blueDark};
      font-size: calc(${Constants.fontSize.md} + 0.5rem);
      font-weight: 600;
      margin: 7px 0 4px;
    }
  }
`;

export const BuyButton = styled(GenericButton)`
  color: #fff;
  background-color: ${Colors.greenDark};
  padding: 5px 25px;
  border-radius: 10px;
`;
