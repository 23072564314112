/**
 * Objeto contendo todas as rotas do aplicativo
 */
const Routes = {
  HOME: "/",
  SIGN_UP: "/criar-conta",
  PROFILE: "/perfil",
  COURSES: "/cursos/",
  CHECKOUT: "/checkout",
  COURSE: "/curso",
  MY_PLAN: "/meu-plano",
  STUDY: "/estudar",
  PERFORMANCE: "/desempenho",
  ALL_COURSES: "/todos-os-cursos",
  ACHIEVEMENTS: "/conquistas",
  GAMEFICATION: "/gameficacao",
  CONTENT: "/conteudo",
  STORE: "/loja",
  COURSE_DETAILS: "/detalhes-curso",
  BUY_COURSE: "/comprar",
  PURCHASE_RESULT: "/purchase_result",
  QUESTIONS: "/questoes",
  QUESTIONS_QUICKIE: "/questoes/rapidinhas",
  QUESTIONS_TEST: "/questoes/simulado",
  QUESTIONS_SUBJECT: "/questoes/por-materia",
  QUESTIONS_STAGE: "/questoes/por-volume",
  FIRST_ACCESS: "/primeiro-acesso",
  PLACEMENT_TEST: "/teste-nivelamento",
};

export default Routes;

export const WithLayout = [
  "/",
  "/perfil",
  "/curso",
  "/meu-plano",
  "/estudar",
  "/desempenho",
  "/todos-os-cursos",
  "/achievements",
  "/gameficacao",
  "/loja",
  "/detalhes-curso",
];
