import React, { createContext, useContext, useEffect } from "react";
import { State, Sender } from "xstate";
import { useMachine } from "@xstate/react";
import { Context, Event } from "./ParentMachine/types";
import { blockStudyMachine } from "./ParentMachine";
import { useActiveCourse } from "../../Store/reducers/course";

const stateContext = createContext<State<Context, Event> | undefined>(
  undefined,
);
const actionsContext = createContext<Sender<Event> | undefined>(undefined);

export const CurrentStudyingBlockProvider: React.FC = props => {
  const activeCourse = useActiveCourse();
  const [state, send] = useMachine(blockStudyMachine, {
    devTools: true,
  });

  useEffect(() => {
    if (!activeCourse) return;

    send({ type: "SET_COURSE", data: activeCourse });
  }, [activeCourse, send]);

  return (
    <stateContext.Provider value={state}>
      <actionsContext.Provider value={send}>
        {props.children}
      </actionsContext.Provider>
    </stateContext.Provider>
  );
};

export const useCurrentBlockState = () => {
  const state = useContext(stateContext);

  if (!state) {
    throw "useCurrentBlockState must be used inside a CurrentStudyingBlockProvider provider";
  }

  return state;
};

export const useCurrentBlockActions = () => {
  const send = useContext(actionsContext);

  if (!send) {
    throw "useCurrentBlockActions must be used inside a CurrentStudyingBlockProvider provider";
  }

  return send;
};
