import React, { useEffect, useState, useCallback } from "react";
import { Button, Icon, Toast } from "./styles";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import Routes from "../../Config/routes";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthSelector } from "../../Store/reducers/auth";
import { useCourseSelector } from "../../Store/reducers/course";

const routesToHideToast = [
  Routes.QUESTIONS,
  Routes.CONTENT,
  Routes.BUY_COURSE,
  Routes.CHECKOUT,
];

const TrialToast: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [daysTillTrialEnds, setDaysTillTrialEnds] = useState(0);
  const { user } = useAuthSelector();
  const { activeCourse } = useCourseSelector();
  const history = useHistory();
  const { pathname } = useLocation();

  const redirectToBuyCoursePage = useCallback(() => {
    history.push(Routes.BUY_COURSE, {
      coursePath: activeCourse?.cursoRef,
      courseId: activeCourse?.cursoRef.split("/").pop(),
    });
  }, [activeCourse?.cursoRef, activeCourse?.cursoRef, history]);

  useEffect(() => {
    if (!user) return;
    if (!user.cadastro?.expiracaoTeste) return;

    const startDate = new Date().getTime();
    const endDate = user.cadastro.expiracaoTeste.toDate().getTime();
    const remainingDays = differenceInCalendarDays(endDate, startDate);
    if (remainingDays > 0) {
      setDaysTillTrialEnds(remainingDays);
    }
  }, [user]);

  useEffect(() => {
    const isTestingCourse = !!activeCourse?.matricula?.testeGratis;
    const shouldShowToastInThisRoute = !routesToHideToast.find(route => {
      return pathname.startsWith(route);
    });

    if (isTestingCourse && shouldShowToastInThisRoute) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [pathname, activeCourse?.matricula?.testeGratis]);

  return showToast && daysTillTrialEnds > 0 ? (
    <Toast>
      <Icon />
      <span>
        Atenção! Seu período de avaliação termina em {daysTillTrialEnds}
        {daysTillTrialEnds > 1 ? " dias" : " dia"}
      </span>
      {activeCourse && (
        <Button onClick={redirectToBuyCoursePage}>Compre já!</Button>
      )}
    </Toast>
  ) : null;
};

export default TrialToast;
