import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import { useUserSelector } from "../../Store/reducers/auth";
import { Gamefication } from "../../Interfaces/Gamefication";
import { db } from "../../Config/firebase";
import { User } from "../../Interfaces/User";

interface State {
  gamefication?: Gamefication;
}

type Action = { type: "SET_DATA"; gamefication: Gamefication };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, gamefication: action.gamefication };
    default:
      return state;
  }
};

const stateContext = createContext<State | undefined>(undefined);

export const GamificationProvider: React.FC = props => {
  const user = useUserSelector();
  const [state, dispatch] = useReducer(reducer, {});

  const watchGamification = useCallback(userId => {
    db.collection("users")
      .doc(userId)
      .onSnapshot(userSnap => {
        const data = userSnap.data() as User;

        if (!data.gameficacao) return;

        dispatch({ type: "SET_DATA", gamefication: data.gameficacao });
      });
  }, []);

  useEffect(() => {
    if (!user?.gameficacao) return;

    dispatch({ type: "SET_DATA", gamefication: user.gameficacao });
  }, [user, user?.gameficacao]);

  useEffect(() => {
    if (!user) return;

    watchGamification(user.uid);
  }, [user, watchGamification]);

  return (
    <stateContext.Provider value={state}>
      {props.children}
    </stateContext.Provider>
  );
};

interface UseGamification extends Partial<Gamefication> {
  getExpToNextLevel(): number;
}

export const useGamification = (): UseGamification => {
  const state = useContext(stateContext);

  if (!state)
    throw new Error(
      "useGamefication should be used inside GamificationProvider",
    );

  const { gamefication } = state;

  const getExpToNextLevel = useCallback(() => {
    const nextLevel = (gamefication?.nivel ?? 0) + 1;

    const exp = 22.5 * Math.pow(nextLevel, 2) - 22.5 * nextLevel;

    return Math.floor(exp / 10) * 10;
  }, [gamefication?.nivel]);

  return { ...gamefication, getExpToNextLevel } as UseGamification;
};
