import { useState, useCallback } from "react";

export const useDisclosure = (defaultIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [isDirty, setIsDirty] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    setIsDirty(true);
  }, []);
  const onOpen = useCallback(() => {
    setIsOpen(true);
    setIsDirty(true);
  }, []);
  const onToggle = useCallback(() => setIsOpen(prevIsOpen => !prevIsOpen), []);

  return { isOpen, onOpen, onClose, onToggle, isDirty };
};
