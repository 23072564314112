import styled from "styled-components";
import Card from "../Card";

export interface Props {
  size?: "xs" | "md" | "lg";
}

const getSize = (size: Props["size"]) => {
  switch (size) {
    case "xs":
      return "400px";

    case "md":
      return "600px";

    case "lg":
      return "1200px";

    default:
      return "400px";
  }
};

export const Container = styled(Card)<Props>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  max-width: ${({ size }) => getSize(size)};
  width: 100%;
  overflow-y: auto;
  min-height: 200px;
  max-height: 80vh;
  margin: auto;
  background: #fff;

  .modal-close-button {
    margin-left: auto;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
`;
