import React, { useState, useEffect } from "react";
import { Toast } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmileWink } from "@fortawesome/free-solid-svg-icons";
import { useUserSelector } from "../../Store/reducers/auth";

const PurchaseCompletedToast: React.FC = () => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const user = useUserSelector();

  useEffect(() => {
    const showToast = () => setIsToastVisible(true);
    document.addEventListener("purchase-success", showToast);

    return () => document.removeEventListener("purchase-success", showToast);
  }, []);

  useEffect(() => {
    if (isToastVisible) {
      const timeoutID = setTimeout(() => {
        setIsToastVisible(false);
      }, 15000);

      // Quando o toast estiver visível, adiciona padding ao topo da página, para evitar que elementos nesta área sejam ocultados pelo toast
      document.body.style.setProperty("padding-top", "50px");

      return () => {
        clearTimeout(timeoutID);
        document.body.style.setProperty("padding-top", "unset");
      };
    }
  }, [isToastVisible]);

  return isToastVisible && user ? (
    <Toast>
      <p>Parabéns! Agora vem com a gente que essa vaga será sua!</p>
      <FontAwesomeIcon icon={faSmileWink} color="#fff" className="icon" />
    </Toast>
  ) : null;
};

export default PurchaseCompletedToast;
