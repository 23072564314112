import styled, { css } from "styled-components";
import { Colors } from "../../../../Assets/Colors";
import { Constants } from "../../../../Assets/Constants";
import { FieldError } from "react-hook-form";
import React from "react";

const labelWidth = "5rem";
const labelMargin = "1rem";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  changeProfilePicture?: boolean;
  isStatic?: boolean;
  noShadow?: boolean;
  filled?: boolean;
}

const setBackgroundStyle = (filled?: boolean, disabled?: boolean) => {
  if (disabled) return Colors.gray;
  if (filled) return Colors.blue;
  return Colors.white;
};

const inputStyles = css`
  display: block;
  width: 100%;
  background: ${Colors.white};
  border: solid 1px ${Colors.grayLight};
  border-radius: ${Constants.radius.sm};
  padding: 0.2rem 0.3rem;
`;

export const Button = styled.button<ButtonProps>`
  width: 10rem;
  height: 1.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${({ filled }) => (filled ? Colors.white : Colors.blueDark)};
  background: ${({ filled, disabled }) => setBackgroundStyle(filled, disabled)};
  box-shadow: ${({ noShadow }) => (noShadow ? undefined : Constants.shadow)};
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};
  top: ${({ changeProfilePicture }) =>
    changeProfilePicture ? "105%" : "109%"};
  left: ${({ changeProfilePicture }) =>
    changeProfilePicture ? "50%" : undefined};
  right: ${({ changeProfilePicture }) =>
    changeProfilePicture ? undefined : "10%"};
  transform: ${({ changeProfilePicture, isStatic }) =>
    changeProfilePicture && !isStatic ? "translateX(-50%)" : undefined};
  border-radius: ${Constants.radius.sm};
  border: ${({ noShadow }) =>
    noShadow ? `solid 1px ${Colors.grayLight}` : undefined};

  & > * {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: calc(${labelMargin} + ${labelWidth});
`;

export const Divider = styled.hr`
  border-bottom-color: ${Colors.black};
  opacity: 0.6;
  margin-top: -1rem;
  margin-bottom: 1rem;
`;

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
};

export const Input = styled.input<InputProps>`
  ${inputStyles};
  border-color: ${({ error }) => (error ? Colors.red : undefined)};
`;

export const Select = styled.select<{ error?: FieldError }>`
  ${inputStyles};
  border-color: ${({ error }) => (error ? Colors.red : undefined)};
`;

export const Label = styled.span<{ error?: FieldError }>`
  margin-right: ${labelMargin};
  width: ${labelWidth};
  flex-shrink: 0;
  color: ${({ error }) => (error ? Colors.red : undefined)};
`;

interface OverlayProps {
  onClick: any;
}

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 101;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 30rem;
`;
