export function maskTEL(v: string) {
  if (!v) return "";

  return v
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");
}

export function maskCPF(v = ""): string {
  if (!v) return "";

  return v
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
}

export function unmaskCPF(v: string): string {
  if (!v) return "";

  return v.replace(/\./g, "").replace(/-/g, "");
}

export function unmaskTEL(v: string): string {
  if (!v) return "";

  return v
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
    .replace(" ", "");
}

export function maskCNPJ(v: string) {
  if (!v) return "";

  return v
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d)(\d{2})$/, "$1-$2");
}

export function maskIE(v: string) {
  if (!v) return "";

  return v
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2");
}

export function maskRG(v: string) {
  if (!v) return "";

  return v
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{5})(\d)/, "$1.$2")
    .replace(/(\d{9})(\d)/, "$1-$2");
}

export function maskCEP(v: string) {
  if (!v) return "";

  return v.replace(/\D/g, "").replace(/^(\d{5})(\d)/, "$1-$2");
}

export function maskCARD(v: string) {
  if (!v) return "";

  return v
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1.$2")
    .replace(/(\d{4})(\d)/, "$1.$2")
    .replace(/(\d{4})(\d)/, "$1.$2")
    .replace(/(\d{4})(\d)/, "$1.$2");
}

export function maskDATE(v: string) {
  if (!v) return "";

  return v
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2");
}

export function maskBRL(v = 0) {
  if (typeof v !== "number") return "";

  const formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(v);
}

export function maskEMAIL(v: string) {
  if (!v) return "";

  return v.replace(/\s/g, "");
}
