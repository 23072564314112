import { Machine } from "xstate";
import { Context, Event, Schema } from "./types";
import { actions } from "./actions";
import { services } from "./services";
import { activities } from "./activities";

export const blockStudyMachine = Machine<Context, Schema, Event>(
  {
    id: "current-block-study",
    context: {
      subjectGoal: 0,
      modules: [],
      totalRemainingModules: 0,
      isLastModule: false,
    },
    initial: "idle",
    states: {
      idle: {},
      fetchingModules: {
        invoke: {
          src: "fetchModules",
          onDone: {
            target: "studyingModule",
            actions: ["setModules", "setInitialModule", "setSubjectGoal"],
          },
        },
      },
      studyingModule: {
        activities: ["recordStudyingTime"],
        on: {
          STOP_STUDYING: {
            target: "idle",
            actions: ["resetContext"],
          },
          UPDATE_MODULE: {
            actions: ["updateCurrentModule"],
          },
          NEXT_MODULE: [
            {
              target: "studyingModule",
              actions: ["setNextModule"],
            },
          ],
        },
      },
    },
    on: {
      START_STUDYING: {
        target: "fetchingModules",
        actions: ["resetContext", "setBlock"],
      },
      SET_COURSE: {
        actions: ["setCourse"],
      },
    },
  },
  {
    actions,
    services,
    activities,
  },
);
