import { db, auth } from "../Config/firebase";
import { User, NewUser } from "../Interfaces/User";
import { AnswerRegistry } from "../Interfaces/Answer";

export class UserModel {
  static async getUser(userUID: string) {
    const userRef = await db
      .collection("users")
      .doc(userUID)
      .get();

    const userData = userRef.data() as User;

    if (!userData?.email) return;

    return {
      ...userRef.data(),
      uid: userRef.id,
    } as User;
  }

  static async setupUser(userUID: string, newUserData: NewUser) {
    const user = auth.currentUser;

    // É necessário que o usuário esteja logado para que possamos escrever no banco
    if (!user) return;

    if (newUserData.password) {
      await user.updatePassword(newUserData.password);
      delete newUserData.password;
    }

    const gamification: User["gameficacao"] = {
      nivel: 0,
      experiencia: 0,
      rubis: 0,
      medalhas: [],
      sequencia: 0,
      seguidoresUIDs: [],
      seguindoUIDs: [],
    };

    const registration: Partial<User["cadastro"]> = {
      plataforma: "web",
    };

    const userData = {
      ...newUserData,
      gameficacao: gamification,
      cadastro: registration,
    };

    await db
      .collection("users")
      .doc(userUID)
      .set({
        ...userData,
        uid: userUID,
      });

    const userRef = await db
      .collection("users")
      .doc(userUID)
      .get();

    const userDocData = userRef.data() as User;

    if (!userDocData?.nome) return;

    return userDocData;
  }

  static async update(userUID: string, userData: Partial<User>) {
    await db
      .collection("users")
      .doc(userUID)
      .update(userData);

    return UserModel.getUser(userUID);
  }

  static async createUserAnswers(userUID: string, answer: AnswerRegistry) {
    try {
      return await db
        .collection("users")
        .doc(userUID)
        .collection("respostasQuestoes")
        .add(answer);
    } catch (error) {}
  }
}

export function getUserRef() {
  const user = auth.currentUser;

  return db.collection("users").doc(user?.uid);
}

export async function updateUser(userData: Partial<User>) {
  const userRef = getUserRef();
  await userRef.update(userData);

  return userRef.get();
}
