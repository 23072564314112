import styled from "styled-components";
import { Colors } from "../../Assets/Colors";
import { Constants } from "../../Assets/Constants";

interface ContainerProps {
  variant?: "inline" | "stacked";
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${props => (props.variant === "stacked" ? "column" : "row")};
  align-items: ${props => (props.variant === "stacked" ? "flex-start" : "")};
  margin-bottom: 1rem;
  width: 100%;

  ${({ variant }) => {
    if (variant === "stacked") {
      return `
        label {
          margin-bottom: 0.5rem;
        }
      `;
    } else {
      return `
        label {
          max-width: 100px;
        }
      `;
    }
  }}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface LabelProps {
  hide?: boolean;
}

export const Label = styled.label<LabelProps>`
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  min-width: 50px;

  ${props =>
    props.hide &&
    `
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid ${Colors.grayHalfLight};
  border-radius: 7px;

  .icon {
    margin-right: 0.5em;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  border-radius: 7px;
  padding: 0.2rem 0.5rem;
  width: 100%;
  color: ${Colors.black};
`;

export const ErrorContainer = styled.label`
  color: ${Colors.red};
  font-size: ${Constants.fontSize.xs};
`;
