import React from "react";
import { Title } from "./styles";

interface Props extends React.HtmlHTMLAttributes<Headers> {
  children: string;
  alignment?: string;
  noMargin?: boolean;
}

const CardTitle: React.FC<Props> = ({ children, alignment, className, noMargin }) => {
  return (
    <Title className={className} alignment={alignment} noMargin={noMargin}>
      {children}
    </Title>
  );
};

export default CardTitle;
