import styled from "styled-components";
import { Constants } from "../../Assets/Constants";
import { Colors } from "../../Assets/Colors";

export const Title = styled.h4<{ alignment?: string; noMargin?: boolean }>`
  text-align: ${({ alignment }) => alignment};
  font-weight: bold;
  font-size: ${Constants.fontSize.md};
  color: ${Colors.black};
  margin: ${({ noMargin }) => (noMargin ? "0" : "0 0 1rem")};
`;
