import React from "react";
import ReactDOM from "react-dom";
import { Router } from "./Router";
import * as serviceWorker from "./serviceWorker";
import "./Assets/Fonts/stylesheet.css";
import SideBar from "./Components/SideBar";
import { GlobalStyles } from "./styles";
import { store } from "./Store";
import { Provider } from "react-redux";
import "./tailwind.css";
import "react-circular-progressbar/dist/styles.css";
import "react-image-crop/dist/ReactCrop.css";

import "./Config/fontawesome";
import { CurrentStudyingBlockProvider } from "./Context/Study";

const root = (
  <Provider store={store}>
    <CurrentStudyingBlockProvider>
      <Router>
        <SideBar />
      </Router>
    </CurrentStudyingBlockProvider>
    <GlobalStyles />
  </Provider>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
