import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import uniqueId from "lodash.uniqueid";
import { useDispatch } from "react-redux";
import ReactCrop, { Crop } from "react-image-crop";
import EditProfileModal from "./Components/EditProfileModal";
import { useQuery } from "react-query";

import CardTitle from "../CardTitle";

import { maskCPF, maskTEL } from "../../Utils/masks";

import { AuthActions, useAuthSelector } from "../../Store/reducers/auth";
import { useActiveCourse } from "../../Store/reducers/course";

import Routes from "../../Config/routes";
import { db, storage } from "../../Config/firebase";

import { CourseConcurse } from "../../Interfaces/Concurse";

import {
  BackgroundImage,
  Button,
  CardContainer,
  Container,
  GraphicalContent,
  LockIcon,
  MotivationLine,
  ProfileContent,
  TextContent,
  Title,
  UserData,
  UserLabel,
  UserProfilePicture,
  InputFile,
  CropperOverlay,
  CropperOverlayContent,
  CropperOverlayButtonsContainer,
} from "./styles";
import { Images } from "../../Assets/Images";

const WelcomeCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crop, setCrop] = useState<Crop>({ aspect: 1 });
  const [selectedImageBase64, setSelectedImageBase64] = useState<string>("");
  const [selectedImageFile, setSelectedImageFile] = useState<File>();
  const [motivationText, setMotivationText] = useState(["", "", ""]);
  const [title, setTitle] = useState("");
  const [isInProfileView, setIsInProfileView] = useState(false);
  const authState = useAuthSelector();
  const courseState = useActiveCourse();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { data: backgroundImage } = useQuery(
    courseState?.cursoRef,
    getBackgroundImg,
  );
  const inputRef = useRef<HTMLInputElement>();
  const activeVolume = courseState?.activeVolume;
  const userName = authState.user?.nome;

  async function getBackgroundImg(courseRef = "") {
    if (!courseRef) return "";

    const courseSnap = await db.doc(courseRef).get();

    if (!courseSnap.exists) return "";

    const courseData = courseSnap.data() as CourseConcurse;

    return courseData?.imagemTitulo || "";
  }

  const getPercentageCompleteness = useCallback(() => {
    const volume = courseState?.activeVolume;
    const percentage =
      ((volume?.nBlocosMCompletados || 0) / (volume?.nBlocosM || 1)) * 100;
    return `${parseFloat(percentage.toFixed(2))}%`;
  }, [courseState?.activeVolume]);

  useEffect(() => {
    switch (pathname) {
      case Routes.PROFILE:
        return setIsInProfileView(true);
      case Routes.HOME:
        const volume = courseState?.activeVolume;
        setTitle(`Bem-vindo, ${authState.user?.nome}!`);
        setMotivationText([
          `Você já concluiu ${getPercentageCompleteness()} da ${volume?.nome ||
            ""}!`,
          "Cada Fase concluída é um passo",
          "na conquista do seu objetivo",
        ]);
        break;
      case Routes.MY_PLAN:
        setTitle(`O sucesso começa com um plano`);
        setMotivationText([
          "Seguir o plano de estudo e completar blocos",
          "diariamente é fundamental para se vencer",
          "nesta maratona. Mantenha-se firme!",
        ]);
        break;
      case Routes.STUDY:
        setTitle(`${authState.user?.nome}, vá se acostumando`);
        setMotivationText([
          "Cada bloco que você estuda é um",
          "passo na direção do seu objetivo:",
          courseState?.nome as string,
        ]);
        break;
      case Routes.PERFORMANCE:
        setTitle(`${authState.user?.nome}, fique de olho no desempenho`);
        setMotivationText([
          "Tente manter a porcentagem de acertos alta,",
          "para não ter surpresas na prova.",
          "Nos estudos, qualidade é tão importante quanto velocidade",
        ]);
        break;
      case Routes.ACHIEVEMENTS:
        setTitle("Estas são suas medalhas");
        setMotivationText([
          "Mesmo depois de ser aprovado você vai olhar",
          "com orgulho e carinho para elas. Guardaremos",
          "elas para você para sempre! 💖",
        ]);
        break;
      case Routes.GAMEFICATION:
        setTitle("Gameficação");
      case Routes.STORE:
        setTitle(`${authState.user?.nome}, não é brincadeira!`);
        setMotivationText([
          "Diversas pesquisas mostram que se aprende",
          "melhor com alegria e competição,",
          "e é isso que propomos!",
        ]);
        break;
      default:
        break;
    }

    setIsInProfileView(false);
  }, [
    pathname,
    courseState,
    authState.user,
    getPercentageCompleteness,
    activeVolume,
    userName,
    courseState?.activeVolume,
    courseState?.nome,
    authState.user?.nome,
  ]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setSelectedImageBase64(reader.result as string);
        setSelectedImageFile(file);
      });

      reader.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getCroppedImg = () => {
    const cropData = crop as Required<Crop>;
    const canvas = document.createElement("canvas");
    const image = new Image();
    image.src = selectedImageBase64;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width as number;
    canvas.height = crop.height as number;
    const ctx = canvas.getContext("2d");

    ctx?.drawImage(
      image,
      cropData.x * scaleX,
      cropData.y * scaleY,
      cropData.width * scaleX,
      cropData.height * scaleY,
      0,
      0,
      cropData.width,
      cropData.height,
    );
    return new Promise<Blob>(resolve => {
      canvas.toBlob(blob => blob && resolve(blob), "image/jpeg", 1);
    });
  };

  const dismissImageCropper = () => {
    setSelectedImageBase64("");
    setSelectedImageFile(undefined);
    setCrop({ aspect: 1 });
  };

  const updateProfilePicture = async () => {
    if (authState.user?.uid) {
      const image = crop.x ? await getCroppedImg() : selectedImageFile;
      const storageRef = storage.ref(`avatar/${authState.user?.uid}`);
      image && (await storageRef.put(image));
      const avatar = await storageRef.getDownloadURL();
      dispatch(AuthActions.updateUserProfile(authState.user.uid, { avatar }));
      dismissImageCropper();
    }
  };

  const renderModal = () => {
    if (isModalOpen) {
      return <EditProfileModal onCloseAttempt={toggleModal} />;
    }
    return undefined;
  };

  const renderImageCropper = () => {
    if (selectedImageBase64) {
      return (
        <CropperOverlay>
          <CropperOverlayContent>
            <div />
            <div className="flex flex-col items-center">
              <p className="mb-3">Clique e arraste na foto para cortá-la</p>
              <ReactCrop
                src={selectedImageBase64}
                crop={crop}
                onChange={newCrop => setCrop(newCrop)}
              />
            </div>
            <CropperOverlayButtonsContainer disabled={authState.isLoading}>
              <Button isStatic className="mr-3" onClick={dismissImageCropper}>
                Cancelar
              </Button>
              <Button filled isStatic onClick={updateProfilePicture}>
                {authState.isLoading ? "Alterando foto" : "Alterar foto"}
              </Button>
            </CropperOverlayButtonsContainer>
          </CropperOverlayContent>
        </CropperOverlay>
      );
    }
    return undefined;
  };

  const pagesToNotShow = [Routes.ALL_COURSES, Routes.COURSE_DETAILS];

  return (
    <>
      {!pagesToNotShow.includes(pathname) ? (
        <>
          <Container>
            <InputFile
              ref={inputRef as any}
              type="file"
              id="profile-picture"
              accept=".jpeg, .jpg, .png"
              onChange={onSelectFile}
            />
            <UserProfilePicture
              image={authState.user?.avatar ?? Images.avatarPlaceholder}
              left={isInProfileView}
            >
              {isInProfileView ? (
                <Button
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  changeProfilePicture
                >
                  Alterar foto
                </Button>
              ) : (
                undefined
              )}
            </UserProfilePicture>
            <CardContainer>
              {isInProfileView ? (
                <>
                  <ProfileContent>
                    <CardTitle>Dados Pessoais</CardTitle>
                    <div className="ml-3">
                      <div className="flex">
                        <UserLabel>Nome:</UserLabel>
                        <UserData>
                          {authState.user?.nome} {authState.user?.sobrenome}
                        </UserData>
                      </div>
                      <div className="flex">
                        <UserLabel>Celular:</UserLabel>
                        <UserData>
                          {(authState.user?.celular &&
                            maskTEL(
                              (authState.user?.celular as unknown) as string,
                            )) ||
                            "-"}
                        </UserData>
                      </div>
                      <div className="flex">
                        <UserLabel>Cidade:</UserLabel>
                        <UserData>
                          {authState.user?.cidade
                            ? `${authState.user?.cidade} - ${authState.user?.estado}`
                            : "-"}
                        </UserData>
                      </div>
                      <div className="flex">
                        <UserLabel>Formação:</UserLabel>
                        <UserData>{authState.user?.formacao || "-"}</UserData>
                      </div>
                      <div className="flex relative">
                        <LockIcon />
                        <UserLabel locked>Email:</UserLabel>
                        <UserData locked>{authState.user?.email}</UserData>
                      </div>
                      <div className="flex relative">
                        <LockIcon />
                        <UserLabel locked>CPF:</UserLabel>
                        <UserData locked>
                          {(authState.user?.cpf &&
                            maskCPF(
                              authState.user?.cpf.toString() as string,
                            )) ||
                            "-"}
                        </UserData>
                      </div>
                    </div>
                  </ProfileContent>
                </>
              ) : (
                <>
                  <TextContent>
                    <Title>{title}</Title>
                    {motivationText.map((text, index) => (
                      <MotivationLine line={index} key={uniqueId()}>
                        {text}
                      </MotivationLine>
                    ))}
                  </TextContent>
                </>
              )}
              <GraphicalContent left={isInProfileView}>
                <BackgroundImage
                  image={backgroundImage}
                  cutDirection={isInProfileView ? "right" : "left"}
                />
              </GraphicalContent>
            </CardContainer>
            {isInProfileView ? (
              <Button onClick={toggleModal}>Alterar dados</Button>
            ) : (
              undefined
            )}
          </Container>
          {renderModal()}
          {renderImageCropper()}
        </>
      ) : null}
    </>
  );
};

export default WelcomeCard;
