import { functionsSouthAmericaEast1 } from "../Config/firebase";

interface AddFriendByCodeOrPhoneParams {
  cursoID: string;
  codeOrPhone: string;
}

interface HandleFriendRequestParams {
  cursoID: string;
  targetUserID: string;
  action: "accept" | "reject";
}

export const addFriendByCodeOrPhone = ({
  cursoID,
  codeOrPhone,
}: AddFriendByCodeOrPhoneParams) =>
  functionsSouthAmericaEast1.httpsCallable("addFriend")({
    cursoID,
    codeOrPhone,
  });

export const handleFriendRequest = ({
  cursoID,
  targetUserID,
  action,
}: HandleFriendRequestParams) =>
  functionsSouthAmericaEast1.httpsCallable("handleFriendRequest")({
    cursoID,
    targetUserID,
    action,
  });
