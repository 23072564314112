import React from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { Container, CardHeader, CardBody } from "./styles";
import { Images } from "../../Assets/Images";

interface Props {
  icon?: keyof typeof Images;
  title: string;
  description?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const overlayStyles: React.CSSProperties = {
  backgroundColor: "#ffffffcc",
  zIndex: Number.MAX_SAFE_INTEGER,
  display: "flex",
  alignItems: "center",
};

const dialogContentStyles: React.CSSProperties = {
  backgroundColor: "transparent",
  zIndex: Number.MAX_SAFE_INTEGER,
  padding: 0,
  maxWidth: 400,
};

export const FancyModal: React.FC<Props> = props => {
  return (
    <DialogOverlay
      style={overlayStyles}
      isOpen={props.isOpen}
      onDismiss={props.onClose}
    >
      <DialogContent aria-label={props.title} style={dialogContentStyles}>
        <Container>
          <CardHeader>
            <img src={props.icon && Images[props.icon]} alt="" />
            <h1>{props.title}</h1>
            <p>{props.description}</p>
          </CardHeader>
          <CardBody>{props.children}</CardBody>
        </Container>
      </DialogContent>
    </DialogOverlay>
  );
};
