import styled from "styled-components";
import { Colors } from "../../Assets/Colors";
import { Constants } from "../../Assets/Constants";

export const CardContainer = styled.div<{ disabled?: boolean }>`
  background: ${Colors.white};
  border-radius: ${Constants.radius.base};
  box-shadow: ${Constants.shadow};
  padding: 1.25rem;

  ${props =>
    props.disabled &&
    ` 
      pointer-events: none;
  `} {
  }
`;
