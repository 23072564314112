import React from "react";
import { WithLayout } from "../../Config/routes";
import { useLocation } from "react-router-dom";

import SideBar from "../../Components/SideBar";
import Header from "../../Components/Header";
import WelcomeCard from "../../Components/WelcomeCard";

import MainContent from "./styles";

export const Layout: React.FC = ({ children }) => {
  const location = useLocation();

  if (WithLayout.includes(location.pathname)) {
    return (
      <MainContent id="main-content">
        <SideBar />
        <Header />
        <WelcomeCard />
        {children}
      </MainContent>
    );
  } else {
    return <></>;
  }
};

export default Layout;
