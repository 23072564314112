import { camelize } from "../../Utils/string";

interface DefaultInterface {
  [key: string]: string;
}

const constructor = (path: string, svgs: Array<string>) => {
  const Svgs: DefaultInterface = {};

  for (const svg of svgs) {
    if (!svg) continue;

    Svgs[camelize(svg)] = require(`${path}${svg}.svg`);
  }

  return Svgs;
};

/**
 * Objeto contento todas as imagens e ícones do aplicativo
 */
export const Images = {
  logo: require("./logo.svg"),
  simpleLogo: require("./simple-logo.png"),
  logoHorizontal: require("./logoHorizontal.png"),
  diagonalLeftBorder: require("./diagonal-left-border.svg"),
  diagonalRightBorder: require("./diagonal-right-border.svg"),
  coffeeAndBook: require("./coffee-and-book.svg"),
  manThinking: require("./man_thinking.png"),
  computerLock: require("./computerLock.svg"),

  paypal: require("./paypal.png"),

  airplane: require("./Achievements/airplane.svg"),
  board: require("./Achievements/board.svg"),
  brofist: require("./Achievements/brofist.svg"),
  certification: require("./Achievements/certification.svg"),
  champagne: require("./Achievements/champagne.svg"),
  coffee: require("./Achievements/coffee.svg"),
  elephant: require("./Achievements/elephant.svg"),
  entrance: require("./Achievements/entrance.svg"),
  flag: require("./Achievements/flag.svg"),
  graduate: require("./Achievements/graduate.svg"),
  gym: require("./Achievements/gym.svg"),
  handWithRuby: require("./Achievements/hand-with-ruby.svg"),
  lightFixture: require("./Achievements/light-fixture.svg"),
  loudspeaker: require("./Achievements/loudspeaker.svg"),
  medal: require("./Achievements/medal.svg"),
  ninja: require("./Achievements/ninja.svg"),
  partnership: require("./Achievements/partnership.svg"),
  puzzlePieces: require("./Achievements/puzzle-piece.svg"),
  shoes: require("./Achievements/shoes.svg"),
  sunWithCloud: require("./Achievements/sun-with-cloud.svg"),
  target: require("./Achievements/target.svg"),
  trophy: require("./Achievements/trophy.svg"),
  history: require("./history.svg"),
  cap: require("./cap.svg"),
  ebook: require("./ebook.png"),
  career: require("./career.png"),
  mobileApp: require("./mobile-app.png"),
  objective: require("./objective.png"),
  advantageTrophy: require("./trophy.png"),
  subscriptionSuccessTrophy: require("./subscription-success-trophy.png"),
  subscriptionSuccessHourglass: require("./subscription-success-hourglass.png"),
  subscriptionSuccessTarget: require("./subscription-success-target.png"),
  subscriptionSuccessCertification: require("./subscription-success-certification.png"),
  signOutBg: require("./sign-out-bg.png"),
  avatarPlaceholder: require("./avatar-placeholder.png"),
  ruby: require("./ruby.png"),
  logo2: require("./logo-2.png"),
  logo3: require("./logo-3.png"),
  trophyBook: require("./trophyBook.png"),
  agenda: require("./agenda.png"),
  sheet: require("./sheet.png"),
  lamp: require("./lamp.png"),
  searchBook: require("./searchBook.png"),
  bookPile: require("./bookPile.png"),
  forwardArrow: require("./f-arrow.svg"),
  rocket: require("./rocket.png"),
  fuelPump: require("./fuel-pump.png"),
  essay: require("./essay.png"),
  bookFruit: require("./book-fruit.png"),
  strategy: require("./strategy.png"),
  clock: require("./clock.png"),
};

// Gamefication

export const Game = constructor("./Game/", [
  "book-with-glasses",
  "book",
  "certificate",
  "clipboard",
  "filled-polygon",
  "hourglass",
  "polygon",
  "ruby",
  "bell",
  "brick-wall",
  "control",
  "ruby",
  "test",
  "essay",
  "clipboard",
  "bell",
  "pendulum",
  "chess",
  "ball-shoe",
  "ice-cream",
]);
