import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Video = styled.video`
  border-radius: 10px;
  margin-bottom: 2rem;
  width: 100%;
`;
