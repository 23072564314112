import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Images } from "../../Assets/Images";
import Button from "../../Components/Button";
import { TextInput } from "../../Components/TextInput";
import Routes from "../../Config/routes";
import { AuthActions } from "../../Store/reducers/auth";
import { validateEmail } from "../../Utils/email";
import { getFirebaseErrorMessage } from "../../Utils/errorMessages";
import { firebase, auth } from "../../Config/firebase";
import {
  Buttons,
  Container,
  Form,
  FormHeader,
  Left,
  Right,
  RightMessages,
} from "./styles";
import { UserModel } from "../../Services/User";
import { NewUser } from "../../Interfaces/User";
import { getUserNames } from "../../Utils/string";

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();
  const [doingLogin, setDoingLogin] = useState<boolean>(false);

  const doSignInWithEmail = async (userData: any) => {
    try {
      setDoingLogin(true);

      const { user } = await auth.signInWithEmailAndPassword(
        userData.email,
        userData.password,
      );

      if (!user) return;

      dispatch(AuthActions.getUserData(user.uid));
    } catch (error) {
      setDoingLogin(false);

      Swal.fire({
        title: getFirebaseErrorMessage(error.code),
        icon: "error",
      });
    }
  };

  const doSignInWithFirebaseProvider = useCallback(
    async (providerName: "google" | "facebook") => {
      try {
        setDoingLogin(true);

        let provider: firebase.auth.AuthProvider | undefined;

        if (providerName === "google") {
          provider = new firebase.auth.GoogleAuthProvider();
        }

        if (providerName === "facebook") {
          provider = new firebase.auth.FacebookAuthProvider();
        }

        if (!provider) return;

        const { user } = await auth.signInWithPopup(provider);

        if (!user) return;

        const { firstName, lastName } = getUserNames(
          user.displayName as string,
        );

        const userData: NewUser = {
          nome: firstName,
          sobrenome: lastName,
          email: user.email ?? "",
        };
        await UserModel.setupUser(user?.uid, userData);

        dispatch(AuthActions.getUserData(user.uid));
      } catch (error) {
        if (error.code === "auth/account-exists-with-different-credential") {
          Swal.fire({
            title: "Erro ao tentar fazer login",
            text: getFirebaseErrorMessage(error.code),
            icon: "error",
          });
        }
        console.log({ error });
      } finally {
        setDoingLogin(false);
      }
    },
    [dispatch],
  );

  const resetPassword = useCallback(async () => {
    try {
      const result = await Swal.fire({
        title: "Insira seu e-mail",
        input: "text",
        confirmButtonText: "Alterar senha",
        showCancelButton: true,
      });

      if (result.dismiss) return;

      if (!validateEmail(result.value as string)) {
        Swal.fire({
          title: "E-mail inválido!",
          icon: "error",
        });
        return;
      }

      await auth.sendPasswordResetEmail(result.value as string);

      Swal.fire({
        title: "E-mail enviado com sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Falha ao enviar e-mail.",
        text: "Entre em contato com o suporte",
        icon: "error",
      });
    }
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Aprovamais - Login</title>
      </Helmet>
      <Left>
        <img src={Images.logoHorizontal} alt="Aprova Mais Concursos" />
        <FormHeader>
          <h1>Login</h1>
          <Link to={Routes.SIGN_UP}>Não tenho cadastro</Link>
        </FormHeader>
        <Form onSubmit={handleSubmit(doSignInWithEmail)}>
          <TextInput
            label="Email"
            name="email"
            id="email"
            aria-label="Digite seu email"
            errorMessage={errors.email && errors.email.message}
            inputRef={register({
              required: "Campo obrigatório",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Email inválido",
              },
            })}
          />
          <TextInput
            label="Senha"
            name="password"
            type="password"
            id="password"
            aria-label="Digite sua senha"
            errorMessage={errors.password && errors.password.message}
            inputRef={register({
              required: "Campo obrigatório",
            })}
          />
          <Buttons>
            <Button
              type="button"
              variant="link"
              size="md"
              isBlock
              disabled={doingLogin}
              onClick={resetPassword}
            >
              Esqueci minha senha
            </Button>
            <Button size="md" isBlock disabled={doingLogin} type="submit">
              Entrar
            </Button>
            <Button
              size="md"
              isBlock
              disabled={doingLogin}
              onClick={() => doSignInWithFirebaseProvider("facebook")}
            >
              Entrar com Facebook
            </Button>
            <Button
              isBlock
              disabled={doingLogin}
              size="md"
              onClick={() => doSignInWithFirebaseProvider("google")}
            >
              Entrar com Google
            </Button>
          </Buttons>
        </Form>
      </Left>
      <Right>
        <RightMessages>
          <div>
            <span>Sonhe, dedique-se</span>
          </div>
          <div>
            <span>conquiste!</span>
          </div>
        </RightMessages>
        <img src={Images.manThinking} alt="" />
      </Right>
    </Container>
  );
};

export default SignIn;
