import styled, { css } from "styled-components";
import { Images } from "../../Assets/Images";
import { Colors } from "../../Assets/Colors";
import { Link } from "react-router-dom";
import { Constants } from "../../Assets/Constants";

export const Container = styled.aside`
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 100%;
  width: ${Constants.sidebarWidth};
  background: ${Colors.grayLighter};
  padding: 2.625rem 0;
`;

export const Logo = styled.img.attrs({ src: Images.logo2 })`
  margin: 0 auto;
  max-width: 120px;
`;

const MenuItemStyles = css`
  display: block;
  padding: 0.625rem 1.6rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  color: ${Colors.graySlate};
  &:hover {
    color: ${Colors.blue};
  }
`;

export const MenuItemLink = styled(Link)<{ selected?: boolean }>`
  ${MenuItemStyles};
  color: ${({ selected }) => (selected ? Colors.blue : Colors.graySlate)};
  background: ${({ selected }) => (selected ? Colors.blueLight : undefined)};
`;

export const MenuItemAnchor = styled.a`
  ${MenuItemStyles};
`;

export const MenuItemButton = styled.button`
  ${MenuItemStyles};
`;

export const MenuItemIcon = styled.span`
  width: 2.3rem;
  display: inline-block;
`;

export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const MenuContainer = styled.div<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
