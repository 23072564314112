import React, { useCallback } from "react";
import { Container } from "./styles";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Colors } from "../../Assets/Colors";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variantColor?: keyof typeof Colors;
  variant?: "outline" | "link" | "ghost";
  isBlock?: boolean;
  size?: "xs" | "md" | "lg";
  isRounded?: boolean;
  leftIcon?: FontAwesomeIconProps["icon"] | React.ReactElement;
  rightIcon?: FontAwesomeIconProps["icon"] | React.ReactElement;
  ref?: any;
}

const Button: React.FC<Props> = props => {
  const iconContainer = useCallback(
    (icon: typeof props.leftIcon, position: "left" | "right") => {
      return (
        <span data-icon-direction={position}>
          {typeof icon === "string" ? <FontAwesomeIcon icon={icon} /> : icon}
        </span>
      );
    },
    [props],
  );

  return (
    <Container {...props} ref={props.ref}>
      {props.leftIcon && iconContainer(props.leftIcon, "left")}
      {props.children}
      {props.rightIcon && iconContainer(props.rightIcon, "right")}
    </Container>
  );
};

export default Button;
