import { useQuery } from "react-query";

import { Signature } from "../Interfaces/Signature";

import { db } from "../Config/firebase";

export interface Offer extends Signature {
  benefits: Array<string>;
}

export const useOffers = () => {
  const offersQuery = useQuery(["offers"], getOffers);

  async function getOffers() {
    const offers: Array<Offer> = [];

    const offerSnap = await db.doc("assinaturas/1TAmxAr24Ggb0t6snIW4").get();

    const offerData = offerSnap.data() as { ofertas: Array<Signature> };

    offerData.ofertas.map((offer, index) => {
      const courseOffer: Offer = {
        id: index.toString(),
        ...offer,
        benefits: [
          "Acesso total ao curso",
          "Focado no edital",
          "Controle de desempenho",
          "Estudo integrado",
          "Acesso aos cursos da área",
          "Acesso a todos os cursos",
        ],
      };

      offers.push(courseOffer);
    });

    return offers;
  }

  return offersQuery;
};
