import React, { useEffect, useState, useCallback } from "react";
import { Images } from "../../Assets/Images";
import Routes from "../../Config/routes";
import { useAuthSelector } from "../../Store/reducers/auth";
import { db } from "../../Config/firebase";
import { User } from "../../Interfaces/User";
import { FancyModal } from "../FancyModal";
import Button from "../Button";

export const SubscribedModal: React.FC = () => {
  const MAX_WAITING_TIME = 300000;
  const STATE_KEY = "state";
  const { user } = useAuthSelector();
  const [state, setState] = useState({
    code: "",
    createdAt: 0,
    show: false,
    isReady: false,
  });

  const updateAndPersist = useCallback(
    (value?: Partial<typeof state>) => {
      const newState = {
        ...state,
        ...value,
      };

      setState(newState);
      localStorage.setItem(STATE_KEY, JSON.stringify(newState));
    },
    [state, setState],
  );

  const redirectToMyPlan = () => {
    window.localStorage.removeItem(STATE_KEY);
    window.location.pathname = Routes.STUDY;
  };

  useEffect(() => {
    const lastState = localStorage.getItem(STATE_KEY);
    if (lastState) {
      setState(JSON.parse(lastState));
    }
  }, []);

  useEffect(() => {
    const onBuildCourse = (e: Event) => {
      updateAndPersist({
        code: (e as CustomEvent).detail,
        createdAt: new Date().getTime(),
        show: true,
      });
    };

    document.addEventListener("build-course", onBuildCourse);

    return () => document.removeEventListener("build-course", onBuildCourse);
  }, [updateAndPersist]);

  useEffect(() => {
    const { createdAt, show } = state;

    if (!show) return;

    const now = new Date().getTime();
    const timeLeft = now - createdAt;
    const timeout = MAX_WAITING_TIME - timeLeft;

    const timeoutID = setTimeout(() => {
      updateAndPersist({
        show: false,
      });
    }, timeout);

    return () => clearTimeout(timeoutID);
  }, [state, updateAndPersist]);

  useEffect(() => {
    if (!user) return;
    if (state.isReady) return;

    // Quando o componente é montado, adiciona um ouvinte para saber se o usuário possui algum curso ativo e se o id deste curso é igual ao id previamente guardado, o que significa que o curso já foi montado no banco, então habilita o botão continuar deste modal
    const stopListening = db
      .collection("users")
      .doc(user.uid)
      .onSnapshot(userSnapshot => {
        const user = userSnapshot.data() as User;

        if (user.idCursoAtivo && user.idCursoAtivo === state.code) {
          stopListening();
          updateAndPersist({
            isReady: true,
          });
        }
      });
  }, [user, state, updateAndPersist]);

  return (
    <FancyModal
      icon="subscriptionSuccessTrophy"
      title="Parabéns pela inscrição!"
      description="Estamos criando o seu curso e quando terminarmos você será
      redirecionado"
      isOpen={state.show}
    >
      <p>
        Após a carga, aproveite para configurar a sua meta de estudo semanal
      </p>
      {state.isReady ? (
        <>
          <img src={Images.subscriptionSuccessTarget} alt="" />
          <Button variantColor="blueDark" size="xs" onClick={redirectToMyPlan}>
            Vamos lá!
          </Button>
        </>
      ) : (
        <>
          <img src={Images.subscriptionSuccessHourglass} alt="" />
          <Button size="xs" disabled>
            Aguarde...
          </Button>
        </>
      )}
    </FancyModal>
  );
};
