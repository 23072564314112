import { db } from "../Config/firebase";

import { getModulePath } from "../Functions/Module";

import { Course, Volume, Module, Block } from "../Interfaces/Course";
import { Area } from "../Interfaces/Area";
import { LocalCourseData, Concurse } from "../Interfaces/Concurse";
import { getUserRef } from "./User";
import { WeeklyStudies } from "../Interfaces/WeeklyStudies";

interface GetCourseInfoProps {
  userID: string;
  courseID: string;
}

export const getCourseInfo = async ({
  userID,
  courseID,
}: GetCourseInfoProps) => {
  if (!userID || !courseID) return;

  const userCourseRef = await db
    .collection("users")
    .doc(userID)
    .collection("cursos")
    .doc(courseID)
    .get();

  const activeCourse = userCourseRef.data() as Course;

  const volume = await db.doc(activeCourse.volumeAtivoRef || "").get();

  activeCourse.courseID = courseID;
  activeCourse.activeVolume = volume.data() as Volume;
  activeCourse.activeBlock = activeCourse.activeVolume?.listaBlocos.find(
    block => block.status === "ativo",
  ) as Block;

  const module = await db.doc(getModulePath(activeCourse)).get();

  activeCourse.activeModule = module.data() as Module;

  return activeCourse;
};

export const getCoursesAreas = async () => {
  const courseAreasRes = await db
    .collection("areasConcursos")
    .doc("areasConcursos")
    .get();
  const areasDoc = courseAreasRes.data();

  if (!areasDoc) return [];

  return areasDoc.areas as Area[];
};

export const getCoursesByArea = async (area: string): Promise<Course[]> => {
  let coursesRef = db.collection("concursos");

  if (area !== "todas") {
    // Embora esta estrutura seja possível, surgiu este erro:
    // Type 'Query<DocumentData>' is missing the following properties from
    // type 'CollectionReference<DocumentData>': id, parent, path, doc, add...
    // @ts-ignore
    coursesRef = coursesRef.where("area", "==", area);
  }

  const coursesRes = await coursesRef.get();
  const courses: Course[] = coursesRes.docs.map(course => ({
    ...(course.data() as Course),
  }));

  return courses ?? [];
};

export const getCoursesByArea2 = async (
  area: string,
): Promise<LocalCourseData[]> => {
  let coursesRef = db.collection("concursos");

  if (area !== "todas") {
    // Embora esta estrutura seja possível, surgiu este erro:
    // Type 'Query<DocumentData>' is missing the following properties from
    // type 'CollectionReference<DocumentData>': id, parent, path, doc, add...
    // @ts-ignore
    coursesRef = coursesRef.where("area", "==", area);
  }

  const coursesRes = await coursesRef.where("status", "==", "aberto").get();
  const concourses: Concurse[] = coursesRes.docs.map(course => ({
    ...(course.data() as Concurse),
  }));

  const concoursesCourses = await Promise.all(
    concourses.map(async concourse => {
      const coursesRef = await db
        .collection(`concursos/${concourse.codigo}/cursos`)
        .get();

      const courses = coursesRef.docs.map(
        course =>
          ({
            ...course.data(),
            path: `/concursos/${concourse.codigo}/cursos/${course.id}`,
          } as LocalCourseData),
      );

      return courses.filter(course => course.status === "aberto");
    }),
  );

  return concoursesCourses.flat();
};

export const getCourseWeeklyStudies = async (courseId: string) => {
  const userRef = getUserRef();

  const weeklyStudiesRef = await userRef
    .collection("cursos")
    .doc(courseId)
    .collection("estudosSemanais")
    .get();

  if (weeklyStudiesRef.empty) return;

  const docData = weeklyStudiesRef.docs[0].data() as WeeklyStudies;

  return docData.semanas;
};
