import React, { useState } from "react";
import {
  Container,
  Label,
  Input,
  InputContainer,
  ErrorContainer,
  Wrapper,
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Para acessibilidade, todo input precisa de uma legenda, caso não queria
   * que ela seja visível, utilize a prop isLabelVisible={false}
   */
  label: string;
  isLabelVisible?: boolean;
  errorMessage?: string;
  variant?: "inline" | "stacked";
  inputRef?: any;
  containerStyle?: React.CSSProperties;
}

export const TextInput: React.FC<Props> = props => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    label,
    isLabelVisible = true,
    variant,
    inputRef: innerRef,
    errorMessage,
  } = props;

  return (
    <Container variant={variant} style={props.containerStyle}>
      <Label hide={!isLabelVisible} htmlFor={props.id}>
        {label}
      </Label>
      <Wrapper>
        <InputContainer>
          <Input
            {...props}
            type={isVisible ? "text" : props.type}
            ref={innerRef}
          />
          {props.type === "password" && (
            <FontAwesomeIcon
              aria-label={isVisible ? "Mostrar senha" : "Ocultar senha"}
              onClick={() => setIsVisible(!isVisible)}
              icon={isVisible ? "eye" : "eye-slash"}
              className="icon"
            />
          )}
        </InputContainer>
        <ErrorContainer>{errorMessage}</ErrorContainer>
      </Wrapper>
    </Container>
  );
};
