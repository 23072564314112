import styled from "styled-components";
import { Colors } from "../Assets/Colors";
import { Constants } from "../Assets/Constants";

const GenericButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: ${Colors.black};
  font-size: ${Constants.fontSize.base};

  &:focus {
    outline: 1px dotted #212121 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
`;

export default GenericButton;
