/**
 * Itera sobre um array de objetos e retorna o primeiro objeto cujo a chave seja exatamente igual ao valor provido
 *
 * @param list Qualquer array de obejtos
 * @param key alguma chave que estes objetos possuam
 * @param value um possível valor para esta chave
 */
export function getArrayItemByKey<T>(
  list: T[] = [],
  key: keyof T,
  value: any = "",
) {
  return list?.find(listItem => {
    return listItem[key] === value;
  }) as T | undefined;
}

export function copyArrayWithRandomValues<T>(
  array: T[],
  maxLength: number,
  set = new Set<T>(),
): T[] {
  const uniqueValuesOnly = Array.from(new Set(array));
  const limit =
    uniqueValuesOnly.length >= maxLength ? maxLength : uniqueValuesOnly.length;

  if (set.size === limit) return Array.from(set);

  set.add(array[Math.floor(Math.random() * array.length)]);

  return copyArrayWithRandomValues(array, maxLength, set);
}
