import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { User } from "../../Interfaces/User";
import { db } from "../../Config/firebase";
import { AppState } from "..";
import { ThunkAction } from "redux-thunk";
import { UserModel } from "../../Services/User";

interface State {
  isLoading: boolean;
  user?: User;
  errorCode?: string;
}

interface AuthData {
  email: string;
  password: string;
}

type Action =
  | { type: "auth/LOGIN_SUCCESS"; user: User }
  | { type: "auth/LOGOUT" }
  | { type: "auth/LOADING"; isLoading: boolean }
  | { type: "auth/CLEAN_UP" }
  | { type: "auth/ERROR"; errorCode: string }
  | { type: "auth/UPDATE_USER"; user: User };

const initialState: State = {
  isLoading: true,
  user: undefined,
  errorCode: undefined,
};

export const authReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "auth/LOADING":
      return { ...state, isLoading: action.isLoading };
    case "auth/LOGIN_SUCCESS":
      return {
        ...state,
        user: action.user,
        isLoading: false,
      };
    case "auth/LOGOUT":
      return { ...state, user: undefined, isLoading: false };
    case "auth/ERROR":
      return { ...state, isLoading: false, errorCode: action.errorCode };
    case "auth/CLEAN_UP":
      return {
        user: undefined,
        errorCode: undefined,
        isLoading: false,
      };
    case "auth/UPDATE_USER":
      return {
        ...state,
        user: action.user,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const useAuthSelector = () =>
  useSelector<AppState, State>(store => store.auth);

export const useUserSelector = () =>
  useSelector<AppState, User | undefined>(store => store.auth.user);

export const AuthActions = {
  /**
   * Action que ficará observando se o usuário está ou não logado, se estiver, busca seus dados no banco e atualiza o estado. Caso o usuário saia da aplicação, remove seus dados do estado automaticamente.
   */
  updateUserProfile(
    userUID: string,
    userData: Partial<User>,
  ): ThunkAction<Promise<void>, AppState, never, Action> {
    return async dispatch => {
      dispatch({ type: "auth/LOADING", isLoading: true });
      await db
        .collection("users")
        .doc(userUID)
        .update(userData);
      const updatedUserData = await UserModel.getUser(userUID);

      if (!updatedUserData) return;

      dispatch({
        type: "auth/UPDATE_USER",
        user: updatedUserData,
      });
    };
  },
  updateUserData(userData: Partial<User>) {
    return async (
      dispatch: Dispatch<Action>,
      getState: () => { auth: State },
    ) => {
      dispatch({ type: "auth/LOADING", isLoading: true });
      const uid = getState().auth.user?.uid;
      await db
        .collection("users")
        .doc(uid)
        .update(userData);
      if (uid) {
        const updatedUserData = await UserModel.getUser(uid);
        if (!updatedUserData) return;

        dispatch({
          type: "auth/UPDATE_USER",
          user: updatedUserData,
        });
      }
    };
  },
  setLoadingUser(isLoading: boolean): Action {
    return { type: "auth/LOADING", isLoading };
  },
  getUserData(
    userID?: string,
  ): ThunkAction<Promise<void>, AppState, never, Action> {
    return async dispatch => {
      if (!userID) return;

      const userData = await UserModel.getUser(userID);

      if (!userData) return;

      dispatch({
        type: "auth/LOGIN_SUCCESS",
        user: userData,
      });
    };
  },
};
