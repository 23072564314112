import styled from "styled-components";
import { Colors } from "../../Assets/Colors";
import { Constants } from "../../Assets/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Toast = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.orange};
  border-top-left-radius: ${Constants.radius.sm};
  border-top-right-radius: ${Constants.radius.sm};
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 2rem;
  white-space: nowrap;
  color: ${Colors.black};
`;

export const Icon = styled(FontAwesomeIcon).attrs({ icon: "stopwatch" })`
  font-size: ${Constants.fontSize.md};
  margin-right: 1rem;
`;

export const Button = styled.button`
  background: ${Colors.green};
  padding: 0.3rem 1rem;
  border-radius: ${Constants.radius.xs};
  text-transform: uppercase;
  margin-left: 1rem;
  color: ${Colors.white};
`;
