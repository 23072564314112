import React from "react";
import {
  Container,
  Logo,
  MenuItemLink,
  MenuItemIcon,
  Menu,
  MenuItemAnchor,
  MenuItemButton,
  MenuContainer,
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uniqueId from "lodash.uniqueid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Routes from "../../Config/routes";
import { useLocation } from "react-router-dom";
import { useCourseSelector } from "../../Store/reducers/course";
import { auth } from "../../Config/firebase";

interface MenuItem {
  label: string;
  icon?: string;
  link: string;
  isNonAuthRoute?: boolean;
  isLogOff?: boolean;
  newTab?: true;
}

const SideBar: React.FC = () => {
  const { activeCourse } = useCourseSelector();
  const { pathname } = useLocation();

  const mainNavigationMenuItems: MenuItem[] = [
    {
      label: "Início",
      icon: "book",
      link: Routes.HOME,
    },
    {
      label: "Meu Plano",
      icon: "calendar-check",
      link: Routes.MY_PLAN,
    },
    {
      label: "Estudar",
      icon: "graduation-cap",
      link: Routes.STUDY,
    },
    {
      label: "Desempenho",
      icon: "chart-bar",
      link: Routes.PERFORMANCE,
    },
  ];
  const bottomNavigationMenuItems: MenuItem[] = [
    {
      label: "Mais cursos",
      link: Routes.ALL_COURSES,
    },
    {
      label: "Perfil",
      link: Routes.PROFILE,
    },
    {
      label: "Blog e notícias",
      link: "https://www.aprovamais.com/blog.html",
      isNonAuthRoute: true,
      newTab: true,
    },
  ];
  const signOffMenuItem = {
    label: "Sair",
  };

  const isInCurrentRoute = (link: string): boolean => {
    return pathname === link;
  };

  const logOut = async () => {
    await auth.signOut();
    window.location.href = Routes.HOME;
  };

  const renderMainNavigation = () => {
    return (
      <Menu>
        {mainNavigationMenuItems.map(item => (
          <MenuItemLink
            to={item.link}
            key={uniqueId()}
            selected={isInCurrentRoute(item.link)}
          >
            <MenuItemIcon>
              <FontAwesomeIcon icon={item.icon as IconProp} />
            </MenuItemIcon>
            <span>{item.label}</span>
          </MenuItemLink>
        ))}
      </Menu>
    );
  };

  const renderBottomNavigation = () => {
    return (
      <React.Fragment>
        {bottomNavigationMenuItems.map(item => {
          if (item.isNonAuthRoute)
            return (
              <MenuItemAnchor
                href={item.link}
                target={item.newTab ? "_blank" : undefined}
                key={uniqueId()}
              >
                <span>{item.label}</span>
              </MenuItemAnchor>
            );
          return (
            <MenuItemLink
              to={item.link}
              key={uniqueId()}
              selected={isInCurrentRoute(item.link)}
            >
              <span>{item.label}</span>
            </MenuItemLink>
          );
        })}
      </React.Fragment>
    );
  };

  const renderSignOffMenuItem = () => {
    return (
      <MenuItemButton key={uniqueId()} onClick={logOut}>
        <span>{signOffMenuItem.label}</span>
      </MenuItemButton>
    );
  };

  return (
    <Container>
      <div>
        <Logo />
      </div>
      <MenuContainer disabled={!activeCourse}>
        {renderMainNavigation()}
      </MenuContainer>
      <div>
        <MenuContainer disabled={!activeCourse}>
          {renderBottomNavigation()}
        </MenuContainer>
        {renderSignOffMenuItem()}
      </div>
    </Container>
  );
};

export default SideBar;
