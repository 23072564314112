import {
  Button,
  ButtonsContainer,
  Divider,
  Input,
  InputContainer,
  Label,
  ModalContainer,
  Overlay,
  Select,
} from "./styles";
import Card from "../../../Card";
import React, { useEffect, useState, useCallback } from "react";
import { User } from "../../../../Interfaces/User";
import { maskTEL } from "../../../../Utils/masks";
import { useForm } from "react-hook-form";
import { AuthActions, useAuthSelector } from "../../../../Store/reducers/auth";
import { useDispatch } from "react-redux";
import {
  useStates,
  useCities,
  State,
  City,
} from "../../../../Hooks/useLocation";
import { getArrayItemByKey } from "../../../../Utils/array";

interface Props {
  onCloseAttempt: () => void;
}

const EditProfileModal: React.FC<Props> = ({ onCloseAttempt }) => {
  const dispatch = useDispatch();
  const { user, isLoading } = useAuthSelector();
  const [selectedState, setSelectedState] = useState<State>();
  const [selectedCity, setSelectedCity] = useState<City>();
  const { data: states, status: statesStatus } = useStates();
  const { data: cities, isFetching: isFetchingCities } = useCities(
    selectedState?.codigo,
  );

  const [maskedCellphone, setMaskedCellphone] = useState(
    maskTEL((user?.celular as unknown) as string),
  );
  const [maskedTelephone, setMaskedTelephone] = useState(
    maskTEL((user?.telefone as unknown) as string),
  );

  const { register, handleSubmit, errors } = useForm<User>({
    defaultValues: {
      nome: user?.nome,
      sobrenome: user?.sobrenome,
      celular: (maskedCellphone as unknown) as number,
      telefone: (maskedTelephone as unknown) as number,
      estado: getArrayItemByKey<State>(
        states,
        "nome",
        user?.estado,
      )?.codigo.toString(),
      cidade: getArrayItemByKey<City>(
        cities,
        "nome",
        user?.cidade,
      )?.codigo.toString(),
    },
  });

  useEffect(() => {
    const selectedState = getArrayItemByKey<State>(
      states,
      "nome",
      user?.estado,
    );
    setSelectedState(selectedState);
  }, [states]);

  useEffect(() => {
    const selectedCity = getArrayItemByKey<City>(cities, "nome", user?.cidade);
    setSelectedCity(selectedCity);
    console.log({ selectedCity });
  }, [cities]);

  const updateUserData = async (form: Partial<User>) => {
    const user = {
      ...form,
      estado: selectedState?.nome,
      cidade: selectedCity?.nome,
    };

    dispatch(AuthActions.updateUserData(user));
    onCloseAttempt();
  };

  const onStateChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedState = getArrayItemByKey<State>(
        states,
        "codigo",
        Number(e.target.value),
      );
      setSelectedState(selectedState);
    },
    [states],
  );

  const onCityChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedCity = cities?.find(city => {
        return city.codigo === Number(e.target.value);
      });
      setSelectedCity(selectedCity);
    },
    [cities],
  );

  const maskPhoneNumber = (
    inputEvent: React.ChangeEvent<HTMLInputElement>,
    maskedField: "celular" | "telefone",
  ) => {
    inputEvent.persist();
    const { value } = inputEvent.target;
    if (maskedField === "celular") setMaskedCellphone(maskTEL(value));
    else setMaskedTelephone(maskTEL(value));
  };

  return (
    <>
      <ModalContainer>
        <Card title="Alterar dados" titleAlignment="left">
          <Divider />
          <form onSubmit={handleSubmit(updateUserData)}>
            <InputContainer>
              <Label error={errors.nome}>Nome: </Label>
              <Input
                error={errors.nome}
                ref={register({ required: true })}
                name="nome"
              />
            </InputContainer>
            <InputContainer>
              <Label error={errors.sobrenome}>Sobrenome: </Label>
              <Input
                error={errors.sobrenome}
                ref={register({ required: true })}
                name="sobrenome"
              />
            </InputContainer>
            <InputContainer>
              <Label error={errors.celular}>Celular: </Label>
              <Input
                error={errors.celular}
                ref={register({ required: true, minLength: 15 })}
                name="celular"
                maxLength={15}
                type="tel"
                onChange={(e: any) => maskPhoneNumber(e, "celular")}
                value={maskedCellphone}
              />
            </InputContainer>
            <InputContainer>
              <Label error={errors.telefone}>Telefone:</Label>
              <Input
                error={errors.telefone}
                ref={register({})}
                name="telefone"
                maxLength={14}
                type="tel"
                onChange={(e: any) => maskPhoneNumber(e, "telefone")}
                value={maskedTelephone}
              />
            </InputContainer>
            <InputContainer>
              <Label error={errors.estado}>Estado: </Label>
              <Select
                error={errors.estado}
                ref={register({ required: true })}
                name="estado"
                value={selectedState?.codigo}
                onChange={onStateChange}
              >
                <option value="">
                  {statesStatus === "success" && "Selecione uma opção"}
                  {statesStatus === "loading" && "Carregando estados"}
                </option>
                {states?.map(state => (
                  <option key={state.codigo} value={state.codigo}>
                    {state.nome}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer>
              <Label error={errors.cidade}>Cidade: </Label>
              <Select
                error={errors.cidade}
                ref={register({})}
                name="cidade"
                value={selectedCity?.codigo}
                onChange={onCityChange}
                disabled={isFetchingCities || !cities}
              >
                {Array.isArray(cities) ? (
                  <>
                    <option value="">
                      {!isFetchingCities && "Selecione uma opção"}
                      {isFetchingCities && "Carregando cidades"}
                    </option>
                    {cities.map(city => (
                      <option key={city.codigo} value={city.codigo}>
                        {city.nome}
                      </option>
                    ))}
                  </>
                ) : (
                  undefined
                )}
              </Select>
            </InputContainer>
            <InputContainer>
              <Label>Formação: </Label>
              <Input ref={register()} name="formacao" />
            </InputContainer>
            <ButtonsContainer>
              <Button
                type="button"
                isStatic
                noShadow
                onClick={onCloseAttempt}
                disabled={isLoading}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                isStatic
                noShadow
                filled
                disabled={isLoading}
              >
                {isLoading ? "Salvando" : "Salvar"}
              </Button>
            </ButtonsContainer>
          </form>
        </Card>
      </ModalContainer>
      <Overlay onClick={onCloseAttempt} />
    </>
  );
};

export default EditProfileModal;
