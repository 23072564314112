import { useSelector } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../";

import { getCourseInfo } from "../../Services/Course";

import { updateUserInfo } from "../../Functions/User";

import { Course as CourseInterface, Subject } from "../../Interfaces/Course";

interface State {
  isLoading: boolean;
  activeCourse?: CourseInterface;
  error?: string;
}

type Action =
  | { type: "course/LOADING" }
  | { type: "course/SET_ACTIVE_COURSE"; activeCourse: CourseInterface }
  | { type: "course/SYNC_ACTIVE_COURSE"; activeCourse: CourseInterface }
  | { type: "course/ERROR"; error: string };

const initialState: State = {
  isLoading: false,
};

export const courseReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "course/LOADING":
      return { ...state, isLoading: true };
    case "course/SET_ACTIVE_COURSE":
      return { ...state, activeCourse: action.activeCourse, isLoading: false };
    case "course/SYNC_ACTIVE_COURSE":
      return { ...state, activeCourse: action.activeCourse, isLoading: false };
    case "course/ERROR":
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
};

export const useCourseSelector = () =>
  useSelector<AppState, State>(store => store.course);

export const useActiveCourse = () =>
  useSelector<AppState, CourseInterface | undefined>(
    store => store.course.activeCourse,
  );

export const CourseActions = {
  updateActiveCourse(
    courseUID: string,
    cb?: () => void,
  ): ThunkAction<Promise<void>, AppState, never, Action> {
    return async (dispatch, getState) => {
      try {
        const { user } = getState().auth;
        if (!user) return;
        dispatch({ type: "course/LOADING" });

        await updateUserInfo(user.uid, {
          idCursoAtivo: courseUID,
        });

        const activeCourse = (await getCourseInfo({
          userID: user.uid,
          courseID: courseUID || (user.idCursoAtivo as string),
        })) as CourseInterface;

        dispatch({ type: "course/SET_ACTIVE_COURSE", activeCourse });

        if (cb) {
          cb();
        }
      } catch (error) {
        dispatch({ type: "course/ERROR", error });
      }
    };
  },
  syncActiveCourse(
    data?: Partial<CourseInterface>,
  ): ThunkAction<Promise<void>, AppState, never, Action> {
    return async (dispatch, getState) => {
      if (data) {
        const { activeCourse } = getState().course;

        dispatch({
          type: "course/SYNC_ACTIVE_COURSE",
          activeCourse: {
            ...activeCourse,
            ...data,
          } as CourseInterface,
        });

        return;
      }

      const { user } = getState().auth;

      if (!user || !user.uid) return;

      dispatch({ type: "course/LOADING" });

      const activeCourse = (await getCourseInfo({
        userID: user.uid,
        courseID: user.idCursoAtivo as string,
      })) as CourseInterface;

      dispatch({ type: "course/SYNC_ACTIVE_COURSE", activeCourse });
    };
  },
};
