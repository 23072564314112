import styled from "styled-components";
import Card from "../Card";
import { Colors } from "../../Assets/Colors";
import { Constants } from "../../Assets/Constants";

export const Container = styled(Card)`
  overflow: hidden;
  padding: 0;
  max-width: 400px;
  margin: auto;
`;

export const CardHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(94, 184, 255);
  background: linear-gradient(
    180deg,
    rgba(94, 184, 255, 1) 80px,
    rgba(2, 136, 209, 1) 80px
  );
  padding-bottom: 30px;

  img {
    width: 90px;
    resize-mode: contain;
    transform: translate3d(0, 24%, 0);
    margin-bottom: 40px;
  }

  h1 {
    font-size: ${Constants.fontSize.sm};
    font-weight: 400;
    color: #fff;
    margin-bottom: 15px;
  }

  p {
    max-width: 36ch;
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: ${Constants.fontSize.base};
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px 50px;

  p {
    max-width: 33ch;
    color: ${Colors.blueDark};
    text-align: center;
    font-weight: 600;
    font-size: ${Constants.fontSize.base};
    margin-bottom: 30px;
  }

  img {
    width: 90px;
    resize-mode: contain;
    transform: translate3d(0, 14%, 0);
    margin-bottom: 60px;
  }
`;
