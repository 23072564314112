export const Constants = {
  sidebarWidth: "12.625rem",
  moduleHeaderHeight: "130px",
  innerPadding: "3rem",
  radius: {
    full: "9999px",
    base: "1.25rem",
    sm: "9px",
    xs: "4px",
  },
  shadow: "0px 3px 6px #00000029",
  fontSize: {
    xl: "2.5rem",
    lg: "2.1rem",
    md: "1.4rem",
    sm: "1.2rem",
    base: "1rem",
    xs: "0.75rem",
  },
};
