import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { useUserSelector } from "../../Store/reducers/auth";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { useActiveCourse } from "../../Store/reducers/course";

interface State {
  isReady: boolean;
  isPaymentUpToDate: boolean;
  isRequestPaymentModalOpen: boolean;
}

interface Dispatchers {
  showRequestPaymentModal(p: boolean): void;
}

type Action =
  | { type: "SET_PAYMENT_UP_TO_DATE"; data: boolean }
  | { type: "SET_MODAL_OPEN"; data: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_PAYMENT_UP_TO_DATE":
      return { ...state, isPaymentUpToDate: action.data, isReady: true };
    case "SET_MODAL_OPEN":
      return { ...state, isRequestPaymentModalOpen: action.data };
    default:
      return state;
  }
};

const stateContext = createContext<(State & Dispatchers) | undefined>(
  undefined,
);

export const TrialPeriodProvider: React.FC = props => {
  const user = useUserSelector();
  const activeCourse = useActiveCourse();
  const [state, dispatch] = useReducer(reducer, {
    isReady: false,
    isPaymentUpToDate: true,
    isRequestPaymentModalOpen: false,
  });
  const isDev = process.env.NODE_ENV === "development";

  useEffect(() => {
    if (!activeCourse || !user || !user.cadastro?.expiracaoTeste) return;

    const userDidBuyCourse = !activeCourse.matricula.testeGratis;
    if (userDidBuyCourse) return;

    const today = new Date().getTime();
    const trialExpirationDate = user.cadastro.expiracaoTeste.toDate().getTime();
    const remainingDays = differenceInCalendarDays(trialExpirationDate, today);

    if (remainingDays <= 0 && !isDev) {
      dispatch({
        type: "SET_PAYMENT_UP_TO_DATE",
        data: false,
      });
      dispatch({
        type: "SET_MODAL_OPEN",
        data: true,
      });
    }
  }, [activeCourse, isDev, user]);

  const showRequestPaymentModal = useCallback((open: boolean) => {
    dispatch({
      type: "SET_MODAL_OPEN",
      data: open,
    });
  }, []);

  return (
    <stateContext.Provider value={{ ...state, showRequestPaymentModal }}>
      {props.children}
    </stateContext.Provider>
  );
};

export const useTrialPeriod = () => {
  const state = useContext(stateContext);

  if (!state)
    throw new Error(
      "useAuthorization must be used inside AuthorizationProvider",
    );

  return state;
};
